import { Component, OnInit } from '@angular/core';
import { OpinionLike } from 'src/app/opinionlike';
import { OpinionCommentsLike } from 'src/app/OpinionCommentsLike';
import { Opinion } from 'src/app/opinion';
import { Post } from 'src/app/post';
import { User } from 'src/app/user';
import { OpinionService } from 'src/app/service/opinion.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-one-opinion',
  templateUrl: './one-opinion.component.html',
  styleUrls: ['./one-opinion.component.css']
})
export class OneOpinionComponent implements OnInit {

  opinion: Opinion;

  objectKeys = Object.keys;
  newComment: string = '';
  cUserId: any;

  opinionAnswerValue;
  opinionAnswerId: number;

  modalPost: Opinion = new Opinion();

  subject: String = '';
  feedback: String = '';
  feedbackPost: Post;

  constructor(private opinionService: OpinionService, private router: Router, private userService: UserService) {
    this.cUserId = +localStorage.getItem('cUserId');
  }

  ngOnInit() {
    this.opinion = JSON.parse(localStorage.getItem('selectedOpinion'));
    this.populateData();
  }

  onSelectionChange(opinion_id, value) {
    this.opinionAnswerValue = value;
    this.opinionAnswerId = opinion_id;
  }

  submitAnswer(opinion_id) {
    if (opinion_id == this.opinionAnswerId) {
      this.opinionService.submitAnswer(opinion_id, this.opinionAnswerValue).subscribe(
        data => {
          let Apost: Opinion = data as any;
          this.opinion = Apost;
          this.populateData();
        }, error => {
          console.log('Error in submitting Answer', error);
        }
      );
    } else {
      window.alert("Please Select One Option");
    }
  }

  checkLike(likes: OpinionLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: OpinionCommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doLikePost(opinion_id: number) {
    this.opinionService.doLikeOnPost(opinion_id).subscribe(
      data => {
        let Apost: Opinion = data as any;
        this.opinion = Apost;
        this.populateData();
      },
      error => {
        console.log("error in like", error);
      }
    )
  }

  doUnLikePost(opinion_id: number) {
    this.opinionService.doUnLikeOnPost(opinion_id).subscribe(
      data => {
        let Apost: Opinion = data as any;
        this.opinion = Apost;
        this.populateData();
      },
      error => {
        console.log("error in Unlike", error);
      }
    )
  }

  doLikeComment(comment_id: number, opinion_id: number) {
    this.opinionService.doLikeOnComment(comment_id, opinion_id).subscribe(
      data => {
        let Apost: Opinion = data as any;
        this.opinion = Apost;
        this.populateData();
        this.modalPost = Apost;
      },
      error => {
        console.log("error in like on comment", error);
      }
    )
  }

  doUnLikeComment(comment_id: number, opinion_id) {
    this.opinionService.doUnLikeOnComment(comment_id, opinion_id).subscribe(
      data => {
        let Apost: Opinion = data as any;
        this.opinion = Apost;
        this.populateData();
        this.modalPost = Apost;
      },
      error => {
        console.log("error in Unlike on Comment", error);
      }
    )
  }

  doCommentOnPost(post_id: number) {
    var button = document.getElementById("button") as HTMLButtonElement;
    this.opinionService.doCommentOnPost(post_id, this.newComment).subscribe(
      data => {
        let Apost: Opinion = data as any;
        this.opinion = Apost;
        this.populateData();
        this.newComment = '';
        button.hidden = true;
      },
      error => {
        console.log("Error in Comments ", error);
      }
    )
  }

  focusReply(id) {
    document.getElementById('newComment' + id).focus();
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  populateData() {
    this.opinion.data = [];
    this.opinion.data.push(this.opinion.yes);
    this.opinion.data.push(this.opinion.no);
    this.opinion.data.push(this.opinion.confuse);
    this.opinion.opinioncomments.sort((a, b) => b.opinionComment_id - a.opinionComment_id)
    localStorage.setItem('selectedOpinion', JSON.stringify(this.opinion));
  }

  public pieChartLabels: string[] = ["Yes", "No", "Confused"];
  public pieChartType: string = 'pie';
  public pieChartOptions: any = {
    'backgroundColor': [
      "#008000",
      "#FF0000",
      "#FFCE56"
    ]
  }

  textAreaEmpty(event, postId) {

    var button = document.getElementById("button") as HTMLButtonElement;
    if (this.newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  likesModalHeading: string = 'Likes';
  likedByUsers: User[] = [];

  showLikesModal(heading, opinion: Opinion) {
    this.userService.getUsersWhoLikedOpinion(opinion.opinion_id).subscribe(
      (res: User[]) => {
        this.likedByUsers = res;
      }, err => {
        console.log('Error in fetching users', err);
      }
    );
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(opinion: Opinion) {
    if (opinion.content) {
      window.open(opinion.content);
    }
  }

  openCommentsModal(post: Opinion) {
    this.modalPost = post;
    document.getElementById('commentsModalButton').click();
  }

  hidePost(post) {
    this.router.navigate(['/opinion']);
  }

  savePost(post) {
    window.alert('Post saved successfully!');
  }

  feeback(post) {
    this.subject = '';
    this.feedback = '';
    this.feedbackPost = post;
    document.getElementById('feedbackButton').click();
  }

  sendFeedback() {
    let feedback = {
      'subject': this.subject,
      'feedback': this.feedback,
      'post': this.feedbackPost,
      'userId': this.cUserId
    }
    this.userService.sendFeedback(feedback).subscribe(
      res => {
        console.log(res);
      }
    );
  }

  goBack() {
    this.router.navigate(['/opinion']);
  }

}
