import { Component, OnInit } from "@angular/core";
import { PostService } from "../../services/post.service";
import { Post } from "../../post";
import { Router } from "@angular/router";
import { Parameter } from "src/app/parameter";

@Component({
  selector: "app-dateandeventall",
  templateUrl: "./dateandeventall.component.html",
  styleUrls: ["./dateandeventall.component.css"],
})
export class DateandeventallComponent implements OnInit {
  datesAndEventsPosts: Post[] = [];

  constructor(private postService: PostService, private router: Router) {}

  ngOnInit() {
    this.getAllPosts();
  }

  goBack() {
    this.router.navigate(['home']);
  }

  getAllPosts() {
    let params = new Parameter();
    params.category = 0;
    this.postService.getImpDatesEvents(params).subscribe(
      (data) => {
        this.datesAndEventsPosts = data as any;
        this.datesAndEventsPosts = this.datesAndEventsPosts.filter(
          (post) => post.isActive === 1
        );
      },
      (error) => {
        console.log("Error in fetching data", error);
      }
    );
  }

  showOneImpEvent(index) {
    localStorage.setItem(
      "impEvent",
      JSON.stringify(this.datesAndEventsPosts[index])
    );
    this.router.navigate(["/imp-event"]);
  }
}
