import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  password: string;
  confirmPassword: string;
  otp: number;
  passwordNotMathced: boolean = false;
  otpNotMatched: boolean = false;

  constructor(private router: Router, private loginService: LoginService) {
    this.email = localStorage.getItem('forEmail');
    if (!localStorage.getItem('forEmail')) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    localStorage.removeItem('forEmail');
  }

  goBack() {
    this.router.navigate(['/login']);
  }

  goHome(){
    this.router.navigate(['/login']);
  }

  goAboutUs(){
    this.router.navigate(['/aboutUs']);
  }

  goPrivacy(){
    this.router.navigate(['/privacy']);
  }

  updatePassword() {
    if (this.password == this.confirmPassword) {
      this.loginService.updatePassword(this.email, this.password, this.otp).subscribe(
        data => {
          if (data == true) {
            localStorage.setItem('pasUpd', JSON.stringify(true));
            this.router.navigate(['/login']);
          } else {
            this.otpNotMatched = true;
          }
        }, error => {

        }
      );
    } else {
      this.passwordNotMathced = true;
    }
  }

}
