import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { StoragePostService } from "./storage-post.service";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Parameter } from "../parameter";
import { User } from "../user";
import { PrivacyForm } from "../settings/models/privacyForm";
import { GeneralFeedback } from "../settings/models/generalFeedback";
import { UserDetail } from "../userDetails";
import { UserInfo } from "../userInfo";
import { Feedback } from "../admin/shared/models/feedback";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }),
};

@Injectable({
  providedIn: "root",
})
export class UserService implements CanActivate {
  url = this.storageService.URL;

  constructor(
    private http: HttpClient,
    private storageService: StoragePostService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return localStorage.getItem("cUserId") === "0";
  }

  getAllRecentActiveUsers() {
    let cUserId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/user/recentActive/" + cUserId, httpOptions)
      .pipe();
  }

  getAllUsersToFollow() {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/user/getAllUsersToFollow/" + userId, httpOptions)
      .pipe();
  }

  getLoggedInUser() {
    let user = {
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/user/getLoggedInUser", user, httpOptions)
      .pipe();
  }

  getUser(user_id) {
    let user = {
      user_id: user_id,
    };
    return this.http
      .post(this.url + "/user/getLoggedInUser", user, httpOptions)
      .pipe();
  }

  uploadCoverImage(file: File) {
    let formData: FormData = new FormData();
    formData.append(
      "file",
      file,
      Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name
    );
    formData.append("userId", localStorage.getItem("cUserId"));
    return this.http.post(this.url + "/user/uploadCoverImage", formData).pipe();
  }

  uploadProfileImage(file: File) {
    let formData: FormData = new FormData();
    formData.append(
      "file",
      file,
      Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name
    );
    formData.append("userId", localStorage.getItem("cUserId"));
    return this.http
      .post(this.url + "/user/uploadProfileImage", formData)
      .pipe();
  }

  updatePassword(pwd, newPWD) {
    let changePWD = {
      pwd: pwd,
      newPWD: newPWD,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(
        this.url + "/user/updatePasswordFromSettings",
        changePWD,
        httpOptions
      )
      .pipe();
  }

  getFollowUnfollowInfo(currentUserId, userId) {
    return this.http
      .get(
        this.url + "/user/getFollowUnfollowInfo/" + currentUserId + "/" + userId
      )
      .pipe();
  }

  followUser(loggedInUser, userId) {
    return this.http
      .get(this.url + "/user/followUser/" + loggedInUser + "/" + userId)
      .pipe();
  }

  unfollowUser(loggedInUser, userId) {
    return this.http
      .get(this.url + "/user/unfollowUser/" + loggedInUser + "/" + userId)
      .pipe();
  }

  getAllFollowersOfUser(userId, activeTagsOnly: number = 0) {
    return this.http
      .get(
        this.url +
          "/user/getAllFollowersOfUser/" +
          userId +
          "/" +
          activeTagsOnly
      )
      .pipe();
  }

  getAllFollowingsOfUser(userId) {
    return this.http
      .get(this.url + "/user/getAllFollowingsOfUser/" + userId)
      .pipe();
  }

  getUserInfo(userId) {
    return this.http.get(this.url + "/user/getUserInfo/" + userId).pipe();
  }

  updateInfo(userInfo) {
    return this.http.post(this.url + "/user/updateInfo", userInfo).pipe();
  }

  sendFeedback(feedback) {
    feedback.postId = feedback.post.post_id;
    feedback.post = null;
    return this.http.post(this.url + "/user/sendFeedback", feedback).pipe();
  }

  blockUser(userId, cUserId) {
    return this.http
      .get(this.url + "/user/blockUser/" + cUserId + "/" + userId)
      .pipe();
  }

  getBannerImage(userId, type) {
    return this.http
      .get(this.url + "/user/getBannerImage/" + userId + "/" + type, {
        responseType: "text",
      })
      .pipe();
  }

  uploadBannerImage(file, userId, type) {
    let formData: FormData = new FormData();
    formData.append(
      "file",
      file,
      Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name
    );
    formData.append("userId", userId);
    formData.append("type", type);
    return this.http
      .post(this.url + "/user/uploadBannerImage", formData, {
        responseType: "text",
      })
      .pipe();
  }

  getUsersWhoLikedPost(postId) {
    return this.http
      .get(this.url + "/user/getUsersWhoLikedPost/" + postId)
      .pipe();
  }

  getUsersWhoLikedBlog(postId) {
    return this.http
      .get(this.url + "/user/getUsersWhoLikedBlog/" + postId)
      .pipe();
  }

  getUsersWhoSharedPost(postId) {
    return this.http
      .get(this.url + "/user/getUsersWhoSharedPost/" + postId)
      .pipe();
  }

  checkForNewNotifications(userId) {
    return this.http
      .get(this.url + "/user/checkForNewNotifications/" + userId)
      .pipe();
  }

  getAllUsersForSearch() {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/user/getAllUsersForSearch/" + userId)
      .pipe();
  }

  getUsersWhoLikedOpinion(opinionId) {
    return this.http
      .get(this.url + "/user/getUsersWhoLikedOpinion/" + opinionId)
      .pipe();
  }

  getSubscribersList(blogListId) {
    return this.http
      .get(this.url + "/user/getSubscribersList/" + blogListId)
      .pipe();
  }

  getUsersByCriteria(searchCriteria: Parameter) {
    return this.http.post(
      this.url + "/user/getUsersByCriteria",
      searchCriteria
    );
  }

  saveUser(user: User) {
    return this.http.post(this.url + "/user/saveUser", user);
  }

  getFeedbacksByCriteria(searchCriteria: Parameter) {
    return this.http.post(
      this.url + "/user/getFeedbacksByCriteria",
      searchCriteria
    );
  }

  getUserPrivacyDetails(userId) {
    return this.http.get(this.url + "/user/getPrivacyDetails/" + userId);
  }

  updateUserPrivacyDetails(privacyForm: PrivacyForm) {
    return this.http.post(
      this.url + "/user/updateUserPrivacyDetails",
      privacyForm
    );
  }

  getIPAddress() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "text/plain, */*",
        "Content-Type": "application/json", // We send JSON
      }),
      responseType: "text" as "json", // We accept plain text as response.
    };
    return this.http.get("http://api.ipify.org/?format=json");
  }

  sendGeneralFeedback(feedback: GeneralFeedback) {
    return this.http.post(this.url + "/user/sendGeneralFeedback", feedback);
  }

  updateEmail(userDetail: UserDetail) {
    return this.http.post(this.url + "/user/updateEmail", userDetail);
  }

  deleteAccount(userId) {
    return this.http.get(this.url + "/user/deleteAccount/" + userId);
  }

  activateUser(userId, OTP) {
    return this.http.get(this.url + "/user/activateUser/" + userId + "/" + OTP);
  }

  sendOTP(userId, mobile) {
    return this.http.get(
      this.url + "/user/sendOTPSMS/" + userId + "/" + mobile
    );
  }
}
