import { Component, OnInit, Input } from '@angular/core';
import { Post } from 'src/app/post';

@Component({
  selector: 'app-social-sharing',
  templateUrl: './social-sharing.component.html',
  styleUrls: ['./social-sharing.component.css']
})
export class SocialSharingComponent implements OnInit {

  @Input() post: Post;

  constructor() { }

  ngOnInit() {
  }

  shareOnFB() {
    let url = 'stiridea.com%2F%23sharedPost%2F' + this.post.post_id;
    window.open("https://www.facebook.com/share.php?u=" + url);
  }

  shareOnTwitter() {
    window.open("https://twitter.com/share?original_referer=/&text=" + this.post.content + "&url=" + this.getImagePath(this.post));
  }

  shareOnGoogle() {
    window.open('https://plus.google.com/share?url=' + this.getImagePath(this.post));
  }

  shareOnLinkedin() {
    window.open('https://www.linkedin.com/cws/share?url=' + this.getImagePath(this.post));
  }

  shareOnInsta() {
    window.open('https://www.instagram.com/stiridea');
  }

  shareOnYouTube() {
    window.open('https://www.youtube.com/channel/UC-hQSYnQwkZBsfHAxXnhTUg/?sub_confirmation=1');
  }

  shareOnWhatsapp() {
    window.open('https://web.whatsapp.com:/send?text=' + this.getImagePath(this.post));
  }

  getImagePath(post: Post) {
    if (post.imagePath) {
      if (post.multipleImages) {
        return 'http://stiridea.com:8080/applications/' + post.imagesList[0];
      } else {
        return post.imagePath;
      }
    } else {
      return post.content;
    }
  }

}
