import { Component, OnInit, Input } from "@angular/core";
import { PostService } from "../services/post.service";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { User } from "../user";
import { OpinionService } from "../service/opinion.service";
import { PrivacyForm } from "../settings/models/privacyForm";

@Component({
  selector: "app-newpost",
  templateUrl: "./newpost.component.html",
  styleUrls: ["./newpost.component.css"],
})
export class NewpostComponent implements OnInit {
  postContent: string = "";
  fileList: FileList;
  image: boolean = false;
  progressBar: boolean = false;

  @Input() category = 0;
  showCategory: boolean = false;

  postType: string = "all";
  subCategory: string = "all";

  cUserId;
  followers: User[];
  selectedFollowers: User[] = [];

  messagePost: boolean = false;

  toggled: boolean = false;
  userPrivacy: PrivacyForm = new PrivacyForm();

  @Input() isAdmin = false;
  @Input() isOpinion = false;

  selectedColor;
  colors: any[] = [
    "#ff3",
    "#4000ff",
    "#f90606",
    "#ff0080",
    "#00ff40",
    "#8000ff",
    "#00ff80",
    "#808080",
    "#00bfff",
  ];

  selectedImage;
  backgroundImages: any[] = [
    "assets/backgrounds/1.jpg",
    "assets/backgrounds/2.jpg",
    "assets/backgrounds/3.jpg",
    "assets/backgrounds/4.jpg",
    "assets/backgrounds/5.jpg",
    "assets/backgrounds/6.jpg",
    "assets/backgrounds/7.jpg",
    "assets/backgrounds/8.png",
    "assets/backgrounds/9.jpg",
    "assets/backgrounds/10.jpg",
    "assets/backgrounds/11.jpg",
    "assets/backgrounds/12.jpg",
    "assets/backgrounds/14.jpg",
    "assets/backgrounds/15.jpg",
    "assets/backgrounds/16.jpg",
    "assets/backgrounds/17.jpg",
    "assets/backgrounds/18.jpg",
    "assets/backgrounds/19.jpg",
    "assets/backgrounds/20.jpg"
  ];

  constructor(
    private postService: PostService,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
    private userService: UserService,
    private opinionService: OpinionService
  ) {
    this.cUserId = +localStorage.getItem("cUserId");
  }

  ngOnInit() {
    this.getUserPrivacyDetails();
    this.getAllFollowers();
  }

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    if (this.fileList.length > 1) {
      (document.getElementById("imageTitle") as HTMLInputElement).innerHTML =
        this.fileList.length + " files selected";
    } else {
      (document.getElementById(
        "imageTitle"
      ) as HTMLInputElement).innerHTML = file;
    }
    this.image = true;
  }

  uploadPost() {
    this.spinnerService.show();
    if (this.isAdmin) {
      this.uploadSpecialPost();
    } else if (this.isOpinion) {
      this.uploadOpinionPost();
    } else {
      if (this.image) {
        this.uploadWithImage();
      } else {
        this.uploadWithoutImage();
      }
    }
  }

  uploadSpecialPost() {
    this.postService
      .addSpecialPost(this.fileList, this.postContent, this.category)
      .then(
        (data) => {
          window.location.reload();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  uploadOpinionPost() {
    this.opinionService.uploadOpinion(this.fileList, this.postContent).then(
      (data) => {
        window.location.reload();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  uploadWithImage() {
    let fileArray: File[] = [];
    for (let i = 0; i < this.fileList.length; i++) {
      fileArray.push(this.fileList[i]);
    }
    if (this.category == 3) {
      if (this.selectedFollowers.length > 0) {
        for (let index = 0; index < this.selectedFollowers.length; index++) {
          this.postService
            .addPostForPersonal(
              fileArray,
              this.postContent,
              this.category,
              this.postType,
              this.subCategory,
              this.selectedFollowers[index].user_id,
              this.selectedFollowers[index].userName,
              this.selectedColor,
              this.selectedImage
            )
            .then(
              (data) => {
                this.spinnerService.hide();
                this.router.navigate(["/aboutUs"]).then(() => {
                  this.router.navigate(["/myNetwork"]);
                });
              },
              (error) => {
                this.spinnerService.hide();
                console.log(error);
              }
            );
        }
      } else {
        alert("Please select atleast one user.");
      }
    } else {
      this.postService
        .addPost(
          fileArray,
          this.postContent,
          this.category,
          this.postType,
          this.subCategory,
          this.selectedColor,
          this.selectedImage
        )
        .then(
          (data) => {
            this.spinnerService.hide();
            this.router.navigate(["/aboutUs"]).then(() => {
              this.router.navigate(["/myNetwork"]);
            });
          },
          (error) => {
            this.spinnerService.hide();
            console.log(error);
          }
        );
    }
  }

  uploadWithoutImage() {
    if (this.category == 3) {
      if (this.selectedFollowers.length > 0) {
        for (let index = 0; index < this.selectedFollowers.length; index++) {
          this.postService
            .addPostWithoutImageForPersonal(
              this.postContent,
              this.category,
              this.postType,
              this.subCategory,
              this.selectedFollowers[index].user_id,
              this.selectedFollowers[index].userName,
              this.selectedColor,
              this.selectedImage
            )
            .subscribe(
              (data) => {
                this.spinnerService.hide();
                this.router.navigate(["/aboutUs"]).then(() => {
                  this.router.navigate(["/myNetwork"]);
                });
              },
              (error) => {
                this.spinnerService.hide();
                console.log(error);
              }
            );
        }
      } else {
        alert("Please select atleast one user.");
      }
    } else {
      this.postService
        .addPostWithoutImage(
          this.postContent,
          this.category,
          this.postType,
          this.subCategory,
          this.selectedColor,
          this.selectedImage
        )
        .subscribe(
          (data) => {
            this.spinnerService.hide();
            this.router.navigate(["/aboutUs"]).then(() => {
              this.router.navigate(["/myNetwork"]);
            });
          },
          (error) => {
            this.spinnerService.hide();
            console.log(error);
          }
        );
    }
  }

  showCategories() {
    if (!(this.isAdmin || this.isOpinion)) {
      this.showCategory = true;
      this.messagePost = false;
    }
  }

  hideCategories() {
    this.showCategory = false;
    this.messagePost = false;
  }

  onSelectionChange(value) {
    this.category = value;
  }

  changePostType(value) {
    this.postType = value;
  }

  changeSubCategory(value) {
    this.subCategory = value;
  }

  getAllFollowers() {
    this.userService.getAllFollowersOfUser(this.cUserId, 0).subscribe(
      (data) => {
        this.followers = data as User[];
      },
      (error) => {
        console.log("Error in getting followers", error);
      }
    );
  }

  selectFollower(event, user_id) {
    if (event.target.checked) {
      this.selectedFollowers.push(user_id);
    } else {
      this.selectedFollowers.splice(this.selectedFollowers.indexOf(user_id), 1);
    }
  }

  showMessagePost() {
    this.messagePost = true;
    this.showCategory = false;
  }

  showPostOptions() {
    if (this.messagePost) {
    } else {
      if (!(this.isAdmin || this.isOpinion)) this.showCategory = true;
    }
  }

  handleSelection(event) {
    this.postContent += event.char;
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  setBackgroundColor(color) {
    this.selectedColor = color;
    var iframe = document.getElementsByTagName("iframe")[0];
    iframe.style.backgroundImage = "none";
    iframe.style.backgroundColor = color;
  }

  setBackgroundImage(image) {
    this.selectedImage = image;
    var iframe = document.getElementsByTagName("iframe")[0];
    iframe.style.backgroundImage = "url('"+image+"')";
    iframe.style.backgroundSize = "cover";
    iframe.style.backgroundRepeat = "no-repeat";
  }
}
