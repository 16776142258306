import { Component, OnInit, ViewChild } from '@angular/core';
import { Post } from '../../post';
import { Like } from '../../like';
import { PostService } from '../../services/post.service';
import { Router } from '@angular/router';
import { CommentsLike } from '../../commentsLike';
import { UserService } from '../../services/user.service';
import { User } from '../../user';
import { Location } from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StoragePostService } from 'src/app/services/storage-post.service';

@Component({
  selector: 'app-show-one-post',
  templateUrl: './show-one-post.component.html',
  styleUrls: ['./show-one-post.component.css']
})
export class ShowOnePostComponent implements OnInit {

  post: Post;
  objectKeys = Object.keys;
  newComment: string;
  cUserId: any;
  modalPost: Post = new Post();
  shareContent: string;

  category: any;
  modalPostType: string = 'all';
  modalSubCategory: string = 'all';

  followers: User[];
  selectedFollowers: User[] = [];

  subject: String = '';
  feedback: String = '';
  feedbackPost: Post;

  pageName: string = '';

  @ViewChild('modal') modal;
  @ViewChild('commentsModal') commentsModal;

  constructor(private postService: PostService, private router: Router, private userService: UserService,
    private location: Location, private spinnerService: Ng4LoadingSpinnerService, private storageService: StoragePostService) {
  }

  ngOnInit() {
    if (!localStorage.getItem("user")) {
      this.router.navigate(['/'])
    }
    this.cUserId = +localStorage.getItem('cUserId');
    this.pageName = localStorage.getItem('pageName');
    this.post = this.dataMassagingOfSinglePost(JSON.parse(localStorage.getItem('currentPost')));
  }

  dataMassagingOfSinglePost(post: Post) {
    let imagePath: string = post.imagePath;
    if (imagePath && imagePath.lastIndexOf('http://') > 10) {
      post.multipleImages = true;
      post.imagesList = imagePath.split('http://stiridea.com:8080/applications/');
      post.imagesList = post.imagesList.splice(1, post.imagesList.length);
    }
    post.comments = post.comments.sort((a, b) => b.comment_id - a.comment_id);
    localStorage.setItem('currentPost', JSON.stringify(post));
    return post;
  }

  checkForMoreImages(post) {
    if (post.imagesList && post.imagesList.length > 4) {
      return true;
    }
    return false;
  }

  checkLike(likes: Like[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doSharePost(post_id: number) {
    this.spinnerService.show();
    if (this.category == 3) {
      if (this.selectedFollowers.length > 0) {
        for (let index = 0; index < this.selectedFollowers.length; index++) {
          this.postService.doSharePostAsPersonal(post_id, this.shareContent, this.category, this.modalPostType,
            this.modalSubCategory, this.selectedFollowers[index].user_id, this.selectedFollowers[index].userName).subscribe(
              data => {
                this.spinnerService.hide();
                this.selectedFollowers = [];
                this.router.navigate(['/aboutUs'])
                  .then(() => { this.router.navigate(['/post']) });
              },
              error => {
                this.spinnerService.hide();
                console.log('Error in Sharing post', error);
              }
            )
        }

      } else {
        this.spinnerService.hide();
        alert('Please select atleast one user.');
      }
    } else {
      this.postService.doSharePost(post_id, this.shareContent, this.category, this.modalPostType, this.modalSubCategory).subscribe(
        data => {
          this.spinnerService.hide();
          this.router.navigate(['/aboutUs'])
            .then(() => { this.router.navigate(['/post']) });
        },
        error => {
          this.spinnerService.hide();
          console.log('Error in Sharing post', error);
        }
      )
    }
  }

  sharePost(postIndex: number) {
    this.modalPost = this.post;
  }

  doLikePost(post_id: number) {
    this.postService.doLikeOnPost(post_id).subscribe(
      (data: Post) => {
        this.post = this.dataMassagingOfSinglePost(data);
      },
      error => {
        console.log("error in like", error);
      }
    )
  }

  doLikeComment(comment_id: number, post_id: number) {
    this.postService.doLikeOnComment(comment_id, post_id).subscribe(
      (data: Post) => {
        this.post = this.dataMassagingOfSinglePost(data);
      },
      error => {
        console.log("error in like on comment", error);
      }
    )
  }

  doUnLikePost(post_id: number) {
    this.postService.doUnLikeOnPost(post_id).subscribe(
      (data: Post) => {
        this.post = this.dataMassagingOfSinglePost(data);
      },
      error => {
        console.log("error in Unlike", error);

      }
    )
  }

  doUnLikeComment(comment_id: number, post_id) {
    this.postService.doUnLikeOnComment(comment_id, post_id).subscribe(
      (data: Post) => {
        this.post = this.dataMassagingOfSinglePost(data);
      },
      error => {
        console.log("error in Unlike on Comment", error);
      }
    )
  }

  doCommentOnPost(post_id: number) {
    this.postService.doCommentOnPost(post_id, this.newComment).subscribe(
      (data: Post) => {
        this.post = this.dataMassagingOfSinglePost(data);
        this.newComment = '';
        var button = document.getElementById("button") as HTMLButtonElement;
        button.hidden = true
      },
      error => {
        console.log("Error in Comments ", error);
      }
    )
  }

  onSelectionChange(value) {
    this.category = value;
  }

  changeModalPostType(value) {
    this.modalPostType = value;
  }

  changeModalSubCategory(value) {
    this.modalSubCategory = value;
  }

  focusReply(id) {
    document.getElementById('newComment').focus();
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  getAllFollowers() {
    this.userService.getAllFollowingsOfUser(this.cUserId).subscribe(
      data => {
        this.followers = data as User[];
      }, error => {
        console.log('Error in getting followers', error);
      }
    );
  }

  selectFollower(event, user_id) {
    if (event.target.checked) {
      this.selectedFollowers.push(user_id);
    } else {
      this.selectedFollowers.splice(this.selectedFollowers.indexOf(user_id), 1);
    }
  }

  shareOnFB(post: Post) {
    let url = 'stiridea.com%2F%23sharedPost%2F' + post.post_id;
    window.open("https://www.facebook.com/share.php?u=" + url);
  }

  shareOnTwitter(post: Post) {
    window.open("https://twitter.com/share?original_referer=/&text=" + post.content + "&url=" + post.imagePath);
  }

  shareOnGoogle(post: Post) {
    window.open('https://plus.google.com/share?url=' + post.imagePath);
  }

  shareOnLinkedin(post: Post) {
    window.open('https://www.linkedin.com/cws/share?url=' + post.imagePath);
  }

  textAreaEmpty() {
    // var newComment = ((document.getElementById("newComment") as HTMLInputElement).value);
    var button = document.getElementById("button") as HTMLButtonElement;
    if (this.newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  goBack() {
    this.router.navigate([this.pageName]);
  }

  likesModalHeading: string = 'Likes';
  likedByUsers: User[] = [];

  showLikesModal(heading, post: Post) {
    if (heading == 'Likes') {
      this.userService.getUsersWhoLikedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    } else {
      this.userService.getUsersWhoSharedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    }
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(post: Post) {
    if (post.imagePath) {
      if (post.multipleImages) {
        window.open('http://stiridea.com:8080/applications/' + post.imagesList[0]);
      } else {
        window.open(post.imagePath);
      }
    }
  }

  followUser(user_id) {
    this.userService.followUser(this.cUserId, user_id).subscribe(
      data => {
        this.post.follow = 1;
      }, error => {
        console.log('Error in following', error);
      }
    );
  }

  unfollowUser(user_id) {
    if (confirm("Are you sure to unfollow ?")) {
      this.userService.unfollowUser(this.cUserId, user_id).subscribe(
        data => {
          this.post.follow = 0;
        }, error => {
          console.log('Error in following', error);
        }
      );
    }
  }

  hidePost(post) {
    this.goBack();
  }

  savePost(post) {
    this.postService.savePost(post).subscribe(
      res => {
        if (res) {
          window.alert('Post saved successfully!');
        } else {
          window.alert('Something went wrong.');
        }
      }, err => {
        console.log('Error while saving post', err);
      }
    );
  }

  feeback(post) {
    this.subject = '';
    this.feedback = '';
    this.feedbackPost = post;
    document.getElementById('feedbackButton').click();
  }

  sendFeedback() {
    let feedback = {
      'subject': this.subject,
      'feedback': this.feedback,
      'post': this.feedbackPost,
      'userId': this.cUserId
    }
    this.userService.sendFeedback(feedback).subscribe(
      res => {
        console.log(res);
      }
    );
  }

  showMessage(message: string) {
    window.alert(message);
  }

  showTaggedUsers(post: Post) {
    this.postService.getTaggedUsers(post).subscribe(
      (data: User[]) => {
        this.likedByUsers = data;
        this.likesModalHeading = 'Tagged Users';
        document.getElementById("likesModalButton").click();
      }, err => {
        console.log('Error in getting tagged users', err);
      }
    );
  }

  openCommentsModal(post: Post) {
    this.modalPost = post;
  }

  commentLikeEvent(event) {
    this.post = this.dataMassagingOfSinglePost(this.storageService.modalPost);
  }

}
