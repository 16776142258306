import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MDBBootstrapModule } from "angular-bootstrap-md";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { FooterComponent } from "./footer/footer.component";
import { Routes, RouterModule } from "@angular/router";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { ChartsModule } from "ng2-charts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LoginService } from "./services/login.service";
import { StoragePostService } from "./services/storage-post.service";

import { HttpClientModule } from "@angular/common/http";
import { HomeComponent } from "./components/home/home.component";
import { PopularPostsComponent } from "./components/popular-posts/popular-posts.component";
import { DatesAndEventsComponent } from "./components/dates-and-events/dates-and-events.component";
import { GroupsListComponent } from "./components/groups-list/groups-list.component";
import { RecentlyActiveUserComponent } from "./components/recently-active-user/recently-active-user.component";
import { ShowOnePostComponent } from "./components/show-one-post/show-one-post.component";
import { CelebrityComponent } from "./components/celebrity/celebrity.component";
import { PeopleMayKnowComponent } from "./components/people-may-know/people-may-know.component";
import { PublicComponent } from "./components/public/public.component";
import { LocationStrategy, CommonModule } from "@angular/common";
import { HashLocationStrategy } from "@angular/common";
import { AboutUSComponent } from "./components/about-us/about-us.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { MyPostsComponent } from "./components/my-posts/my-posts.component";
import { MyPopularPostsComponent } from "./components/my-popular-posts/my-popular-posts.component";
import { MyNetworkComponent } from "./components/my-network/my-network.component";
import { GroupsComponent } from "./components/groups/groups.component";
import { PopularGroupsComponent } from "./components/popular-groups/popular-groups.component";
import { OpinionComponent } from "./components/opinion/opinion.component";
import { PopularOpinionComponent } from "./components/popular-opinion/popular-opinion.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { UsersNetworkComponent } from "./components/users-network/users-network.component";
import { SpecialPostComponent } from "./components/special-post/special-post.component";
import { GroupInfoComponent } from "./components/group-info/group-info.component";
import { DateandeventallComponent } from "./components/dateandeventall/dateandeventall.component";
import { DateandeventsingleComponent } from "./components/dateandeventsingle/dateandeventsingle.component";
import { SearchUserComponent } from "./components/search-user/search-user.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";
import { SharePostComponent } from "./components/share-post/share-post.component";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { MyBlogComponent } from "./components/my-blog/my-blog.component";
import { MyDiaryComponent } from "./components/my-diary/my-diary.component";
import { ShareComponent } from "./common/share/share.component";

import { Ng2TelInputModule } from "ng2-tel-input";

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular-6-social-login";
import { NgxEmojiPickerModule } from "ngx-emoji-picker";
import { ModalComponent } from "./common/modal/modal.component";
import { SocialSharingComponent } from "./common/social-sharing/social-sharing.component";
import { CommentsModalComponent } from "./common/comments-modal/comments-modal.component";
import { OneOpinionComponent } from "./components/one-opinion/one-opinion.component";
import { BlogsListComponent } from "./components/blogs-list/blogs-list.component";
import { BlogComponent } from "./components/blog/blog.component";
import { SharedModule } from "./shared/shared.module";
import { UserService } from "./services/user.service";
import { ToastrModule } from 'ngx-toastr';
import { EditorModule } from "@tinymce/tinymce-angular";
import { SafehtmlPipe } from './safehtml.pipe';

const appRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "home", component: HomeComponent },
  { path: "celebrity", component: CelebrityComponent },
  { path: "public", component: PublicComponent },
  { path: "post", component: ShowOnePostComponent },
  { path: "aboutUs", component: AboutUSComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  { path: "myPosts", component: MyPostsComponent },
  { path: "myNetwork", component: MyNetworkComponent },
  { path: "groups", component: GroupsComponent },
  { path: "opinion", component: OpinionComponent },
  { path: "settings", component: SettingsComponent },
  { path: "showUserProfile", component: UsersNetworkComponent },
  { path: "group-info", component: GroupInfoComponent },
  { path: "all-imp-events", component: DateandeventallComponent },
  { path: "imp-event", component: DateandeventsingleComponent },
  { path: "searchUser", component: SearchUserComponent },
  { path: "sharedPost/:id", component: SharePostComponent },
  { path: "notifications", component: NotificationsComponent },
  { path: "myBlogs", component: BlogComponent },
  { path: "myDiary", component: BlogComponent },
  { path: "oneOpinion", component: OneOpinionComponent },
  { path: "blogs", component: BlogsListComponent },
  { path: "diary", component: BlogsListComponent },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "admin",
    loadChildren: "./admin/admin.module#AdminModule",
    canActivate: [UserService],
  },
  {
    path: "settings",
    loadChildren: "./settings/settings.module#SettingsModule",
  },
];

const CONFIG = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("2815088898587888"),
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      "858277416281-5nk9kdvgphrfimgs67v1q457en3qv11g.apps.googleusercontent.com"
    ),
  },
]);

export function provideConfig() {
  return CONFIG;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    HomeComponent,
    PopularPostsComponent,
    DatesAndEventsComponent,
    GroupsListComponent,
    RecentlyActiveUserComponent,
    ShowOnePostComponent,
    CelebrityComponent,
    PeopleMayKnowComponent,
    PublicComponent,
    AboutUSComponent,
    PrivacyComponent,
    ForgotPasswordComponent,
    MyPostsComponent,
    MyPopularPostsComponent,
    MyNetworkComponent,
    GroupsComponent,
    PopularGroupsComponent,
    OpinionComponent,
    PopularOpinionComponent,
    SettingsComponent,
    UsersNetworkComponent,
    SpecialPostComponent,
    GroupInfoComponent,
    DateandeventallComponent,
    DateandeventsingleComponent,
    SearchUserComponent,
    UserInfoComponent,
    SharePostComponent,
    NotificationsComponent,
    MyBlogComponent,
    MyDiaryComponent,
    ShareComponent,
    ModalComponent,
    SocialSharingComponent,
    CommentsModalComponent,
    OneOpinionComponent,
    BlogsListComponent,
    BlogComponent,
    SafehtmlPipe,
  ],
  imports: [
    BrowserModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    ChartsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    SocialLoginModule,
    NgxEmojiPickerModule.forRoot(),
    SharedModule,
    Ng2TelInputModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    EditorModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: AuthServiceConfig, useFactory: provideConfig },
    LoginService,
    StoragePostService,
    UserService,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
