import { Component, OnInit } from '@angular/core';
import { User } from '../../user';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recently-active-user',
  templateUrl: './recently-active-user.component.html',
  styleUrls: ['./recently-active-user.component.css']
})
export class RecentlyActiveUserComponent implements OnInit {

  users: User[];

  more: boolean = false;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.getAllRecentActiveUsers();
  }

  getAllRecentActiveUsers() {
    this.userService.getAllRecentActiveUsers().subscribe(
      data => {
        this.users = data as any;
      },
      error => {
        console.log("Error in fetching Recent Users", error);
      }
    );
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  toggleUserList() {
    this.more = !this.more;
  }

}
