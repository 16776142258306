import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { PostService } from "src/app/services/post.service";
import { Location } from "@angular/common";
import { BlogList } from "src/app/blogList";
import { PrivacyForm } from "src/app/settings/models/privacyForm";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-blogs-list",
  templateUrl: "./blogs-list.component.html",
  styleUrls: ["./blogs-list.component.css"],
})
export class BlogsListComponent implements OnInit {
  blogDiaryUser;
  cUserId;
  showCreateFlag: boolean = false;
  title: string;
  subTitle: string;
  fileList: FileList;
  image: boolean = false;
  imagePath: string;
  visibility: string = "public";

  path;
  pageType: string = "";
  allBlogs: BlogList[] = [];

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private postService: PostService,
    private router: Router,
    private spinner: Ng4LoadingSpinnerService,
    private location: Location,
    private userService: UserService
  ) {}

  goBack() {
    this.router.navigate(['myNetwork']);
  }

  ngOnInit() {
    this.blogDiaryUser = JSON.parse(localStorage.getItem("blog-diary"));
    this.cUserId = localStorage.getItem("cUserId");
    this.getUserPrivacyDetails();
    this.path = this.location.path(false);
    if (this.path === "/blogs") {
      this.pageType = "blogs";
    } else if (this.path === "/diary") {
      this.pageType = "diary";
    } else {
      this.router.navigate(["/home"]);
    }
    this.getBlogList();
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBlogList() {
    if (this.cUserId == this.blogDiaryUser) {
      this.visibility = "all";
    }
    this.postService
      .getBlogList(this.blogDiaryUser, this.pageType, this.visibility)
      .subscribe(
        (data: BlogList[]) => {
          this.allBlogs = data;
          this.hideCreate();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  showCreate() {
    this.title = "";
    this.subTitle = "";
    this.fileList = undefined;
    this.showCreateFlag = true;
  }

  hideCreate() {
    this.showCreateFlag = false;
  }

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    (document.getElementById(
      "imageTitle"
    ) as HTMLInputElement).innerHTML = file;
    this.image = true;
  }

  addNew() {
    this.spinner.show();
    if (this.image) {
      this.uploadWithImage();
    } else {
      this.uploadWithoutImage();
    }
  }

  async uploadWithImage() {
    let file: File = this.fileList[0];
    await this.postService.uploadImage(file).then(
      (data: any) => {
        this.imagePath = data.fileName;
        this.uploadWithoutImage();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  uploadWithoutImage() {
    this.postService
      .addBlogList(
        this.title,
        this.subTitle,
        this.pageType,
        this.imagePath,
        this.visibility
      )
      .subscribe(
        (data) => {
          this.getBlogList();
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
  }

  viewBlog(blog: BlogList) {
    sessionStorage.setItem("blogList", JSON.stringify(blog.blogId));
    sessionStorage.setItem("pPage", this.path);
    if (this.pageType == "blogs") {
      this.router.navigate(["/myBlogs"]);
    } else {
      this.router.navigate(["/myDiary"]);
    }
  }

  onSelectionChange(value) {
    this.visibility = value;
  }

  subscribeBlog(blog: BlogList) {
    this.postService.subscribeBlog(blog).subscribe(
      (data) => {
        this.allBlogs.forEach((b) => {
          if (b === blog) {
            b.subscribed = 1;
          }
        });
      },
      (err) => {
        console.log("Error in subscribe", err);
      }
    );
  }

  unsubscribeBlog(blog) {
    this.postService.unsubscribeBlog(blog).subscribe(
      (data) => {
        this.allBlogs.forEach((b) => {
          if (b === blog) {
            b.subscribed = 0;
          }
        });
      },
      (err) => {
        console.log("Error in subscribe", err);
      }
    );
  }
}
