import { Component, OnInit } from "@angular/core";
import { Group } from "../../group";
import { GroupService } from "../../services/group.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-groups-list",
  templateUrl: "./groups-list.component.html",
  styleUrls: ["./groups-list.component.css"],
})
export class GroupsListComponent implements OnInit {
  allGroups: Group[];
  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private groupService: GroupService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUserPrivacyDetails();
    this.getAllGroups();
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllGroups() {
    this.groupService.getAllGroupsForUser().subscribe(
      (data) => {
        this.allGroups = data as any;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  joinGroup(group_id: number) {
    this.groupService.joinGroup(group_id).subscribe(
      (data) => {
        this.allGroups = data as any;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  goToGroups() {
    this.router.navigate(["/groups"]);
  }

  seeGroupInfo(index) {
    let group = this.allGroups[index];
    localStorage.setItem("selectedGroup", JSON.stringify(group));
    this.router.navigate(["/group-info"]);
  }
}
