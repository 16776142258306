import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Post } from '../../post';
import { PostService } from '../../services/post.service';

@Component({
  selector: 'app-share-post',
  templateUrl: './share-post.component.html',
  styleUrls: ['./share-post.component.css']
})
export class SharePostComponent implements OnInit {

  postId;

  currentPost: Post = new Post();

  constructor(private route: ActivatedRoute, private postService: PostService) { }

  ngOnInit() {
    this.postId = this.route.snapshot.paramMap.get("id");
    this.getPost();
  }

  getPost() {
    this.postService.getSinglePost(this.postId).subscribe(
      res => {
        this.currentPost = res as Post;
      }, err => {
        console.log('Error');
      }
    );
  }

}
