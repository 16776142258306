import { Component, OnInit } from "@angular/core";
import { LoginService } from "../services/login.service";
import { Router } from "@angular/router";
import { StoragePostService } from "../services/storage-post.service";
import {
  AuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angular-6-social-login";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  confirmPassword: string;
  formValue: Number = 1;
  contact: Number;
  address: string = "";

  type: string = "public";

  registrationSuccess: boolean = false;
  passwordUpdate: boolean = false;

  errorMsg: string = "";
  userId = 0;
  OTP;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private dataStorage: StoragePostService,
    private socialAuthService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService,
    private toast: ToastrService,
    private userService: UserService
  ) {
    if (localStorage.getItem("user")) {
      this.router.navigate(["/home"]);
    }
    this.passwordUpdate = JSON.parse(localStorage.getItem("pasUpd"));
  }

  ngOnInit() {
    if (this.passwordUpdate) {
      this.errorMsg = "Password updated successfully.";
    }
    localStorage.removeItem("pasUpd");
  }

  doAuthenticate() {
    if (
      this.email === "stiridea.info@gmail.com" &&
      this.password === "adminLOGIN@1"
    ) {
      localStorage.setItem("cUserId", "0");
      this.router.navigate(["/admin/home-dashboard"]);
    } else {
      this.spinnerService.show();
      this.loginService
        .authenticateUser(this.email, this.password)
        .subscribe((data) => {
          if (data == 1) {
            this.errorMsg = "";
            this.doLogin();
          } else if (data == 0) {
            this.spinnerService.hide();
            this.errorMsg = "Your Account is not Active";
          } else {
            this.spinnerService.hide();
            this.errorMsg = "Wrong Credentials!";
          }
        });
    }
  }

  doLogin() {
    this.loginService.login(this.email, this.password).subscribe(
      (data) => {
        localStorage.setItem("cUserId", data.user_id);
        localStorage.setItem("user", JSON.stringify(data));
        this.dataStorage.user = data;
        this.spinnerService.hide();
        this.router.navigate(["/home"]);
      },
      (error) => {
        this.spinnerService.hide();
        this.errorMsg = "Wrong Credentials!";
      }
    );
  }

  sendOTP() {
    this.loginService.sendOTP(this.email).subscribe(
      (data) => {
        if (data == true) {
          localStorage.setItem("forEmail", this.email);
          this.router.navigate(["/forgotPassword"]);
        } else {
          this.errorMsg = "Email entered is incorrect";
        }
      },
      (error) => {}
    );
  }

  loginForm() {
    this.resetForm();
    this.formValue = 1;
  }

  registrationForm() {
    this.resetForm();
    this.formValue = 2;
  }

  forgotForm() {
    this.resetForm();
    this.formValue = 3;
  }

  resetForm() {
    this.email = "";
    this.password = "";
    this.firstName = "";
    this.lastName = "";
    this.confirmPassword = "";
    this.type = "public";
    this.errorMsg = "";
    this.contact = undefined;
    this.address = "";
  }

  doRegister() {
    if (this.firstName == "" || this.firstName.length < 1) {
      this.toast.error("Please enter first name ", "ERROR!");
    } else if (this.lastName == "" || this.lastName.length < 1) {
      this.toast.error("Please enter last name ", "ERROR!");
    } else if (this.email == "" || this.email.length < 1) {
      this.toast.error("Please enter email address ", "ERROR!");
    } else if (this.password == "" || this.password.length < 1) {
      this.toast.error("Please enter password ", "ERROR!");
    } else if (!this.isEmail(this.email)) {
      this.toast.error("Invalid email address ", "ERROR!");
    } else if (this.contact && this.contact.toString().length < 10) {
      // Need a valid validation here <---
      this.toast.error("Invalid contact number ", "ERROR!");
    } else {
      this.errorMsg = "";
      if (this.password == this.confirmPassword) {
        this.spinnerService.show();
        this.loginService
          .register(
            this.firstName,
            this.lastName,
            this.email,
            this.password,
            this.type,
            this.contact,
            this.address
          )
          .subscribe(
            (data) => {
              if (data !== 0) {
                this.userId = data;
                this.spinnerService.hide();
                if (this.contact && this.contact.toString().length > 9) {
                  // Need to add a valid validation for phone number
                  this.formValue = 4;
                } else {
                  this.formValue = 1;
                  this.registrationSuccess = true;
                  this.loginForm();
                }
              } else {
                this.errorMsg = "Email Already Exists!";
                this.password = "";
                this.confirmPassword = "";
              }
            },
            (error) => {
              console.log(error);
              this.spinnerService.hide();
            }
          );
      } else {
        this.errorMsg = "Password not matched!";
      }
    }
  }

  onSelectionChange(type) {
    this.type = type;
  }

  socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      this.loginSocialSignIn(userData);
    });
  }

  loginSocialSignIn(userData) {
    this.loginService
      .doSocialLogin(userData.name, userData.email)
      .subscribe((data) => {
        localStorage.setItem("cUserId", data.user_id);
        localStorage.setItem("user", JSON.stringify(data));
        this.dataStorage.user = data;
        this.router.navigate(["/home"]);
      });
  }

  onCountryChange(event) {}

  isEmail(search: string): boolean {
    var serchfind: boolean;

    var regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    serchfind = regexp.test(search);
    return serchfind;
  }

  activateUser() {
    this.userService.activateUser(this.userId, this.OTP).subscribe(
      (resp) => {
        if (resp === 1) {
          this.formValue = 1;
          this.toast.success("You have successfully registered", "Success");
          this.loginForm();
        } else {
          this.toast.error("OTP entered is incorrect", "ERROR!");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
