export class Group {
  group_id: number;
  groupName: string;
  groupImage: string;
  memberSize: number;
  userId: number;
  subTitle: string;
  type: string;
  follow?: number;
  createdDate?: Date;
  createdUserName?: string;
  isActive?: number = 0;
}
