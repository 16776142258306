import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  detail: string = '';

  constructor(private staticDataService: NotificationService) {
    this.getStaticData();
  }

  ngOnInit() {
  }

  getStaticData() {
    this.staticDataService.getStaticData('privacy').subscribe(
      data => {
        this.detail = data as string;
        document.getElementById("value").innerHTML = this.detail;
      }, err => {
        console.log(err);
      }
    );
  }

}
