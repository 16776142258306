import { Component, OnInit } from "@angular/core";
import { Opinion } from "../../opinion";
import { OpinionService } from "../../service/opinion.service";
import { OpinionCommentsLike } from "../../OpinionCommentsLike";
import { OpinionLike } from "../../opinionlike";
import { Router } from "@angular/router";
import { User } from "src/app/user";
import { UserService } from "src/app/services/user.service";
import { Post } from "src/app/post";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-opinion",
  templateUrl: "./opinion.component.html",
  styleUrls: ["./opinion.component.css"],
})
export class OpinionComponent implements OnInit {
  opinions: Opinion[];
  backup: Opinion[] = [];

  objectKeys = Object.keys;
  newComment: string;
  cUserId: any;

  opinionAnswerValue;
  opinionAnswerId: number;

  modalPost: Opinion = new Opinion();

  subject: String = "";
  feedback: String = "";
  feedbackPost: Post;

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private opinionService: OpinionService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.cUserId = +localStorage.getItem("cUserId");
    this.getUserPrivacyDetails();
    this.getAllOpinions();
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onSelectionChange(opinion_id, value) {
    this.opinionAnswerValue = value;
    this.opinionAnswerId = opinion_id;
  }

  submitAnswer(opinion_id) {
    if (opinion_id == this.opinionAnswerId) {
      this.opinionService
        .submitAnswer(opinion_id, this.opinionAnswerValue)
        .subscribe(
          (data) => {
            window.location.reload();
          },
          (error) => {
            console.log("Error in submitting Answer", error);
          }
        );
    } else {
      window.alert("Please Select One Option");
    }
  }

  checkLike(likes: OpinionLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: OpinionCommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  getAllOpinions() {
    this.opinionService.getAllOpinions().subscribe(
      (data: Opinion[]) => {
        data = data.filter((op) => op.isActive === 1);
        this.opinions = data as any;
        this.backup = JSON.parse(JSON.stringify(data as any));
      },
      (error) => {
        console.log("error in opinion", error);
      },
      () => {
        this.populateData();
      }
    );
  }

  getPopularPosts() {
    this.opinionService.getAllOpinions().subscribe(
      (data: Opinion[]) => {
        data = data.filter((op) => op.isActive === 1);
        this.opinions = data as any;
        this.backup = JSON.parse(JSON.stringify(data as any));
        this.sortList();
      },
      (error) => {
        console.log("Error in fetching popular opinions", error);
      }
    );
  }

  sortList() {
    this.opinions = this.opinions.sort(
      (a, b) => b.opinioncomments.length - a.opinioncomments.length
    );
  }

  doLikePost(opinion_id: number) {
    this.opinionService.doLikeOnPost(opinion_id).subscribe(
      (data) => {
        let Apost: Opinion = data as any;
        for (let opinion of this.opinions) {
          if (opinion.opinion_id == Apost.opinion_id) {
            var index = this.opinions.indexOf(opinion);
            this.opinions[index] = Apost;
          }
        }
        this.populateData();
      },
      (error) => {
        console.log("error in like", error);
      }
    );
  }

  doUnLikePost(opinion_id: number) {
    this.opinionService.doUnLikeOnPost(opinion_id).subscribe(
      (data) => {
        let Apost: Opinion = data as any;
        for (let opinion of this.opinions) {
          if (opinion.opinion_id == Apost.opinion_id) {
            var index = this.opinions.indexOf(opinion);
            this.opinions[index] = Apost;
          }
        }
        this.populateData();
      },
      (error) => {
        console.log("error in Unlike", error);
      }
    );
  }

  doLikeComment(comment_id: number, opinion_id: number) {
    this.opinionService.doLikeOnComment(comment_id, opinion_id).subscribe(
      (data) => {
        let Apost: Opinion = data as any;
        for (let opinion of this.opinions) {
          if (opinion.opinion_id == Apost.opinion_id) {
            var index = this.opinions.indexOf(opinion);
            this.opinions[index] = Apost;
            this.modalPost = Apost;
          }
        }
        this.populateData();
      },
      (error) => {
        console.log("error in like on comment", error);
      }
    );
  }

  doUnLikeComment(comment_id: number, opinion_id) {
    this.opinionService.doUnLikeOnComment(comment_id, opinion_id).subscribe(
      (data) => {
        let Apost: Opinion = data as any;
        for (let opinion of this.opinions) {
          if (opinion.opinion_id == Apost.opinion_id) {
            var index = this.opinions.indexOf(opinion);
            this.opinions[index] = Apost;
            this.modalPost = Apost;
          }
        }
        this.populateData();
      },
      (error) => {
        console.log("error in Unlike on Comment", error);
      }
    );
  }

  doCommentOnPost(post_id: number) {
    var newComment = (document.getElementById(
      "newComment" + post_id
    ) as HTMLInputElement).value;
    this.opinionService.doCommentOnPost(post_id, newComment).subscribe(
      (data) => {
        let Apost: Opinion = data as any;
        for (let opinion of this.opinions) {
          if (opinion.opinion_id == Apost.opinion_id) {
            var index = this.opinions.indexOf(opinion);
            this.opinions[index] = Apost;
          }
        }
        this.populateData();
      },
      (error) => {
        console.log("Error in Comments ", error);
      }
    );
  }

  focusReply(id) {
    document.getElementById("newComment" + id).focus();
  }

  showUserNetwork(user_id) {
    localStorage.setItem("selectedUser_id", user_id);
    this.router.navigate(["/showUserProfile"]);
  }

  populateData() {
    for (let ind = 0; ind < this.opinions.length; ind++) {
      this.opinions[ind].data = [];
      this.opinions[ind].data.push(this.opinions[ind].yes);
      this.opinions[ind].data.push(this.opinions[ind].no);
      this.opinions[ind].data.push(this.opinions[ind].confuse);
      this.opinions[ind].opinioncomments.sort(
        (a, b) => b.opinionComment_id - a.opinionComment_id
      );
    }
  }

  public pieChartLabels: string[] = ["Yes", "No", "Confused"];
  public pieChartType: string = "pie";
  public pieChartOptions: any = {
    backgroundColor: ["#008000", "#FF0000", "#FFCE56"],
  };

  dateSelected(event) {
    var date: Date = event.value;
    this.opinions = [];
    for (let index = 0; index < this.backup.length; index++) {
      var postDate = new Date(this.backup[index].createdDate);
      if (
        postDate.getDate() == date.getDate() &&
        postDate.getFullYear() == date.getFullYear() &&
        postDate.getMonth() == date.getMonth()
      ) {
        this.opinions.push(this.backup[index]);
      }
    }
  }

  textAreaEmpty(event, postId) {
    var newComment = (document.getElementById(
      "newComment" + postId
    ) as HTMLInputElement).value;
    var button = document.getElementById(
      "button" + postId
    ) as HTMLButtonElement;
    if (newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  likesModalHeading: string = "Likes";
  likedByUsers: User[] = [];

  showLikesModal(heading, opinion: Opinion) {
    this.userService.getUsersWhoLikedOpinion(opinion.opinion_id).subscribe(
      (res: User[]) => {
        this.likedByUsers = res;
      },
      (err) => {
        console.log("Error in fetching users", err);
      }
    );
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(opinion: Opinion) {
    if (opinion.content) {
      window.open(opinion.content);
    }
  }

  openCommentsModal(post: Opinion) {
    this.modalPost = post;
    document.getElementById("commentsModalButton").click();
  }

  hidePost(post) {
    let index = this.opinions.indexOf(post);
    this.opinions.splice(index, 1);
  }

  savePost(post) {
    // this.postService.savePost(post).subscribe(
    //   res => {
    //     if (res) {
    window.alert("Post saved successfully!");
    //     } else {
    //       window.alert('Something went wrong.');
    //     }
    //   }, err => {
    //     console.log('Error while saving post', err);
    //   }
    // );
  }

  feeback(post) {
    this.subject = "";
    this.feedback = "";
    this.feedbackPost = post;
    document.getElementById("feedbackButton").click();
  }

  sendFeedback() {
    let feedback = {
      subject: this.subject,
      feedback: this.feedback,
      post: this.feedbackPost,
      userId: this.cUserId,
    };
    this.userService.sendFeedback(feedback).subscribe((res) => {
      console.log(res);
    });
  }
}
