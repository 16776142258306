import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StoragePostService } from "./storage-post.service";
import { User } from "../user";
import { Post } from "../post";
import { Parameter } from "../parameter";
import { BlogList } from "../blogList";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  }),
};

@Injectable({
  providedIn: "root",
})
export class PostService {
  url = this.storageService.URL;

  constructor(
    private http: HttpClient,
    private storageService: StoragePostService
  ) {}

  getAllPosts(category, type, offset) {
    let user = localStorage.getItem("cUserId");
    return this.http
      .get(
        this.url +
          "/post/all/" +
          user +
          "/" +
          category +
          "/" +
          type +
          "/" +
          offset,
        httpOptions
      )
      .pipe();
  }

  getAllPostsForHomePage(offset) {
    let user = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/all/" + user + "/" + offset, httpOptions)
      .pipe();
  }

  getImpDatesEvents(searchCriteria: Parameter) {
    return this.http
      .post(this.url + "/static/impDatesEvents", searchCriteria, httpOptions)
      .pipe();
  }

  getAllMyPosts(user_id: number, selectedType: string) {
    return this.http
      .get(
        this.url + "/post/myPosts/" + user_id + "/" + selectedType,
        httpOptions
      )
      .pipe();
  }

  getAllMyNetworkPosts(user_id: number, selectedType: string) {
    return this.http
      .get(
        this.url + "/post/getAllMyNetworkPosts/" + user_id + "/" + selectedType,
        httpOptions
      )
      .pipe();
  }

  getUsersPosts(user_id: number, selectedType: string) {
    return this.http
      .get(
        this.url + "/post/userPosts/" + user_id + "/" + selectedType,
        httpOptions
      )
      .pipe();
  }

  getAllPopularPosts() {
    return this.http.get(this.url + "/post/popularPosts", httpOptions).pipe();
  }

  getAllPopularMyPosts(user_id) {
    return this.http
      .get(this.url + "/post/myPopularPosts/" + user_id, httpOptions)
      .pipe();
  }

  async addPost(
    files: File[],
    postContent: string,
    category: any,
    postType,
    subCategory,
    backColor,
    backImage
  ) {
    var filesStringPath = "";
    for (let i = 0; i < files.length; i++) {
      var self = this;
      await this.uploadImage(files[i]).then((data: any) => {
        filesStringPath += data.fileName;
      });
    }
    let post: Post = new Post();
    post.content = postContent;
    post.user_id = Number(localStorage.getItem("cUserId"));
    post.imagePath = filesStringPath;
    post.category = category;
    post.postType = postType;
    post.subCategory = subCategory;
    post.backColor = backColor;
    post.backImage = backImage;
    return this.http.post(this.url + "/post/addPost", post).toPromise();
  }

  async uploadImage(file: File) {
    let formData: FormData = new FormData();
    formData.append(
      "file",
      file,
      Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name
    );
    return await this.http
      .post(this.url + "/post/uploadImage", formData)
      .toPromise();
  }

  async addPostForPersonal(
    files: File[],
    postContent: string,
    category: any,
    postType,
    subCategory,
    personallySharedBy,
    userName,
    backColor, backImage
  ) {
    var filesStringPath = "";
    for (let i = 0; i < files.length; i++) {
      var self = this;
      await this.uploadImage(files[i]).then((data: any) => {
        filesStringPath += data.fileName;
      });
    }
    // formData.append('personallySharedBy', userName);
    // formData.append('personallySharedById', personallySharedBy);
    let post: Post = new Post();
    post.content = postContent;
    post.user_id = Number(localStorage.getItem("cUserId"));
    post.imagePath = filesStringPath;
    post.category = category;
    post.postType = postType;
    post.subCategory = subCategory;
    post.personallySharedBy = userName;
    post.personallySharedById = personallySharedBy;
    post.backColor = backColor;
    post.backImage = backImage;
    return this.http
      .post(this.url + "/post/addPostForPersonal", post)
      .toPromise();
  }

  addPostWithoutImage(postContent: string, category, postType, subCategory, backColor, backImage) {
    let post = {
      content: postContent,
      user_id: localStorage.getItem("cUserId"),
      category: category,
      postType: postType,
      subCategory: subCategory,
      backColor: backColor,
      backImage: backImage
    };
    return this.http.post(this.url + "/post/addPostWithoutImage", post).pipe();
  }

  addPostWithoutImageForPersonal(
    postContent: string,
    category,
    postType,
    subCategory,
    personallySharedBy,
    userName,
    backColor,
    backImage
  ) {
    let user: User = JSON.parse(localStorage.getItem("user"));
    let post = {
      content: postContent,
      user_id: localStorage.getItem("cUserId"),
      category: category,
      postType: postType,
      subCategory: subCategory,
      personallySharedBy: userName,
      personallySharedById: personallySharedBy,
      backColor: backColor,
      backImage: backImage
    };
    return this.http
      .post(this.url + "/post/addPostWithoutImageForPersonal", post)
      .pipe();
  }

  doCommentOnPost(post_id: number, content: string) {
    let comment = {
      post_id: post_id,
      content: content,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/addComment", comment).pipe();
  }

  doLikeOnPost(post_id: number) {
    let like = {
      post_id: post_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/addLike", like).pipe();
  }

  doUnLikeOnPost(post_id: number) {
    let like = {
      post_id: post_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/removeLike", like).pipe();
  }

  doLikeOnComment(comment_id: number, post_id: number) {
    let commentsLike = {
      post_id: post_id,
      comment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/post/comment/addLike", commentsLike)
      .pipe();
  }

  doUnLikeOnComment(comment_id: number, post_id: number) {
    let commentsLike = {
      post_id: post_id,
      comment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/post/comment/removeLike", commentsLike)
      .pipe();
  }

  doSharePost(
    post_id: number,
    shareContent: string,
    category: any,
    postType: string,
    subCategory: string
  ) {
    let post = {
      post_id: post_id,
      user_id: localStorage.getItem("cUserId"),
      shareContent: shareContent,
      category: category,
      postType: postType,
      subCategory: subCategory,
    };
    return this.http.post(this.url + "/post/sharePost", post).pipe();
  }

  doSharePostAsPersonal(
    post_id: number,
    shareContent: string,
    category: any,
    postType: string,
    subCategory: string,
    sharedWithUser: number,
    userName
  ) {
    let user = JSON.parse(localStorage.getItem("user"));
    let post = {
      post_id: post_id,
      user_id: localStorage.getItem("cUserId"),
      shareContent: shareContent,
      category: category,
      postType: postType,
      subCategory: subCategory,
      personallySharedBy: userName,
      personallySharedById: sharedWithUser,
    };
    return this.http.post(this.url + "/post/sharePostAsPersonal", post).pipe();
  }

  getDataAsPerSubCategory(type, postType, subCategory) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(
        this.url +
          "/post/getDataAsPerSubCategory/" +
          type +
          "/" +
          postType +
          "/" +
          subCategory +
          "/" +
          userId
      )
      .pipe();
  }

  getDataAsPerPostType(type, postType) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(
        this.url +
          "/post/getDataAsPerPostType/" +
          type +
          "/" +
          postType +
          "/" +
          userId
      )
      .pipe();
  }

  deletePost(post_id) {
    return this.http.delete(this.url + "/post/deletePost/" + post_id).pipe();
  }

  getSinglePost(postId) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/getSinglePost/" + postId + "/" + userId)
      .pipe();
  }

  async addPostInGroup(files: File[], postContent, groupId, backColor) {
    // let formData: FormData = new FormData();
    // formData.append('file', file, Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name);
    var filesStringPath = "";
    for (let i = 0; i < files.length; i++) {
      var self = this;
      await this.uploadImage(files[i]).then((data: any) => {
        filesStringPath += data.fileName;
      });
    }
    let post = {
      content: postContent,
      user_id: localStorage.getItem("cUserId"),
      groupId: groupId,
      imagePath: filesStringPath,
      backColor: backColor
    };
    return this.http.post(this.url + "/post/addPostInGroup", post).toPromise();
  }

  addPostWithoutImageInGroup(postContent, groupId, backColor) {
    let post = {
      content: postContent,
      user_id: localStorage.getItem("cUserId"),
      groupId: groupId,
      backColor: backColor
    };
    return this.http
      .post(this.url + "/post/addPostWithoutImageInGroup", post)
      .pipe();
  }

  getAllPostsOfGroup(groupId) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/getAllPostsOfGroup/" + groupId + "/" + userId)
      .pipe();
  }

  updatePost(post: Post) {
    return this.http.post(this.url + "/post/updatePost", post).pipe();
  }

  addPostInBlogs(file, postContent, postType) {
    let formData: FormData = new FormData();
    formData.append(
      "file",
      file,
      Math.floor(Math.random() * (999999 - 100000)) + 100000 + file.name
    );
    formData.append("title", postContent);
    formData.append("userId", localStorage.getItem("cUserId"));
    formData.append("postType", postType);
    return this.http.post(this.url + "/post/addPostInBlogs", formData).pipe();
  }

  addPostWithoutImageInBlogs(postContent, postType) {
    let post = {
      content: postContent,
      user_id: localStorage.getItem("cUserId"),
      postType: postType,
    };
    return this.http
      .post(this.url + "/post/addPostWithoutImageInBlogs", post)
      .pipe();
  }

  doLikeOnBlogs(postId) {
    let like = {
      post_id: postId,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/addLikeOnBlogs", like).pipe();
  }

  doUnLikeOnBlogs(post_id: number) {
    let like = {
      post_id: post_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/removeLikeOnBlogs", like).pipe();
  }

  doLikeOnCommentOnBlogs(comment_id: number, post_id: number) {
    let commentsLike = {
      post_id: post_id,
      comment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/post/comment/addLikeOnBlogs", commentsLike)
      .pipe();
  }

  doUnLikeOnCommentOnBlogs(comment_id: number, post_id: number) {
    let commentsLike = {
      post_id: post_id,
      comment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/post/comment/removeLikeOnBlogs", commentsLike)
      .pipe();
  }

  doCommentOnPostOnBlogs(post_id: number, content: string) {
    let comment = {
      post_id: post_id,
      content: content,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/post/addCommentOnBlogs", comment).pipe();
  }

  getAllBlogsOfUser(blogDiaryUser, postType) {
    return this.http
      .get(
        this.url + "/post/getAllBlogsOfUser/" + postType + "/" + blogDiaryUser
      )
      .pipe();
  }

  deleteBlogs(post_id) {
    return this.http.delete(this.url + "/post/deleteBlogs/" + post_id).pipe();
  }

  updateBlogs(post: Post) {
    let editPost = {
      post_id: post.post_id,
      content: post.content,
    };
    return this.http.post(this.url + "/post/updateBlogs", editPost).pipe();
  }

  savePost(post: Post) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/savePost/" + userId + "/" + post.post_id)
      .pipe();
  }

  getSavedPosts(userId) {
    return this.http.get(this.url + "/post/getSavedPosts/" + userId).pipe();
  }

  getAllArchivePosts(type) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/getAllArchivePosts/" + userId + "/" + type)
      .pipe();
  }

  updatePostsView(viewIds) {
    let userId = localStorage.getItem("cUserId");
    return this.http
      .get(this.url + "/post/updatePostsView/" + userId + "/" + viewIds)
      .pipe();
  }

  getAllPopularPostsByPageName(pageName, userId, params) {
    // let userId = localStorage.getItem("cUserId");
    return this.http.post(
      this.url +
        "/post/getAllPopularPostsByPageName/" +
        pageName +
        "/" +
        userId,
      params
    );
  }

  getPostsByParams(params: Parameter) {
    let userId = localStorage.getItem("cUserId");
    return this.http.post(this.url + "/post/getPostByParams/" + userId, params);
  }

  getNetworkPostByParams(params: Parameter) {
    let userId = localStorage.getItem("cUserId");
    return this.http.post(
      this.url + "/post/getNetworkPostByParams/" + userId,
      params
    );
  }

  getTaggedUsers(post: Post) {
    let data = {
      content: post.content,
      category: post.category,
      subCategory: post.subCategory,
      imagePath: post.imagePath,
      user_id: post.user_id,
    };
    return this.http.post(this.url + "/post/getTaggedUsers", data);
  }

  getBlogList(userId, pageType, visibility) {
    let loggedInUser = localStorage.getItem("cUserId");
    return this.http.get(
      this.url +
        "/blogs/getAllBlogList/" +
        userId +
        "/" +
        pageType +
        "/" +
        visibility +
        "/" +
        loggedInUser
    );
  }

  getSingleBlogList(blogId) {
    let loggedInUser = localStorage.getItem("cUserId");
    return this.http.get(
      this.url + "/blogs/getSingleBlogList/" + blogId + "/" + loggedInUser
    );
  }

  addBlogList(title, subTitle, pageType, imagePath, visibility) {
    let blogList = {
      title: title,
      subTitle: subTitle,
      pageType: pageType,
      userId: localStorage.getItem("cUserId"),
      imagePath: imagePath,
      visibility: visibility,
    };
    return this.http.post(this.url + "/blogs/addBlogList", blogList).pipe();
  }

  getBlog(blogId: number) {
    return this.http.get(this.url + "/blogs/getBlog/" + blogId);
  }

  updateBlog(blogList: BlogList) {
    return this.http.post(this.url + "/blogs/updateBlog", blogList);
  }

  deleteBlog(blogId: number) {
    return this.http.get(this.url + "/blogs/deleteBlog/" + blogId);
  }

  subscribeBlog(blog: BlogList) {
    let userId = localStorage.getItem("cUserId");
    return this.http.get(
      this.url + "/blogs/subscribe/" + userId + "/" + blog.blogId
    );
  }

  unsubscribeBlog(blog: BlogList) {
    let userId = localStorage.getItem("cUserId");
    return this.http.get(
      this.url + "/blogs/unsubscribe/" + userId + "/" + blog.blogId
    );
  }

  async addSpecialPost(files: FileList, content: string, category) {
    var imagePath = "";
    if (files) {
      await this.uploadImage(files[0]).then((data: any) => {
        imagePath = data.fileName;
      });
    }
    let post = {
      imagePath: imagePath,
      content: content,
      category: category,
    };
    return this.http.post(this.url + "/static/specialPost", post).toPromise();
  }

  saveImpDatesEventPost(post: Post) {
    return this.http.post(this.url + "/static/saveImpDatesEventPost", post);
  }

  deleteImpDatesEventPost(post: Post) {
    return this.http.get(
      this.url + "/static/deleteImpDatesEventPost/" + post.post_id
    );
  }

  getPostByCriteria(filterCriteria: Parameter) {
    return this.http.post(this.url + "/post/getPostByCriteria", filterCriteria);
  }

  updatePostStatus(post) {
    return this.http.post(this.url + "/static/updatePostStatus", post);
  }
}
