import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NewpostComponent } from "../newpost/newpost.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HeaderComponent } from "./header/header.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { NgxEmojiPickerModule } from "ngx-emoji-picker";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    EditorModule,
    NgxEmojiPickerModule.forRoot(),
  ],
  declarations: [NewpostComponent, HeaderComponent],
  exports:[NewpostComponent, HeaderComponent]
})
export class SharedModule {}
