import { Component, OnInit } from "@angular/core";
import { Post } from "src/app/post";
import { PostService } from "src/app/services/post.service";
import { Parameter } from "src/app/parameter";

@Component({
  selector: "app-special-post",
  templateUrl: "./special-post.component.html",
  styleUrls: ["./special-post.component.css"],
})
export class SpecialPostComponent implements OnInit {
  posts: Post[] = [];

  constructor(private postService: PostService) {}

  ngOnInit() {
    this.getAllSpecialPosts();
  }

  getAllSpecialPosts() {
    let params = new Parameter();
    params.category = 1;
    this.postService.getImpDatesEvents(params).subscribe(
      (resp: Post[]) => {
        this.posts = resp;
        this.posts = this.posts.filter(
          (post) =>
            (post.imagePath !== null || post.imagePath !== "") &&
            post.isActive === 1
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
