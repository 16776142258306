export class PrivacyForm {
  userId: number;
  viewProfile: number = 0;
  viewFriends: number = 0;
  tagMe: number = 0;
  chatWithMe: number = 0;
  securityNotifications?: boolean;
  notificationsTagged?: boolean;
  notificationsReaction?: boolean;
  notificationsFollow?: boolean;
  selectedColor?: string;
}
