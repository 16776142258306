import { Component, OnInit } from '@angular/core';
import { Post } from '../../post';
import { PostService } from '../../services/post.service';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { StoragePostService } from 'src/app/services/storage-post.service';

@Component({
  selector: 'app-my-popular-posts',
  templateUrl: './my-popular-posts.component.html',
  styleUrls: ['./my-popular-posts.component.css']
})
export class MyPopularPostsComponent implements OnInit {

  @Input() allPosts;

  popularPosts: Post[] = [];
  objectKeys = Object.keys;
  popularPostsIds: Number[] = [];

  user_id;

  constructor(private postService: PostService, private router: Router, private storagePostService: StoragePostService) {
    this.user_id = localStorage.getItem('cUserId');
  }

  ngOnInit() {
    this.getAllPopularPosts();
    this.storagePostService.events$.forEach(event => this.getAllPopularPosts());
  }

  getAllPopularPosts() {
    this.postService.getAllPopularMyPosts(this.user_id).subscribe(
      data => {
        this.popularPostsIds = data as Number[];
      }, error => {
        console.log("Error in fetching data", error);
      },
      () => {
        for (let id of this.popularPostsIds) {
          for (let post of this.allPosts) {
            if (post.post_id == id) {
              this.popularPosts.push(post);
            }
          }
        }
      }
    );

  }

  openPost(indexOfPost: number) {
    localStorage.setItem('allPosts', JSON.stringify(this.allPosts));
    this.storagePostService.selectedPost = this.popularPosts[indexOfPost];
    localStorage.setItem('currentPost', JSON.stringify(this.popularPosts[indexOfPost]));
    // if (window.location.href == "http://localhost:4200/#/post") {
    if (window.location.href == "http://stiridea.com/#/post") {
      this.router.navigate(['/aboutUs'])
        .then(() => { this.router.navigate(['/post']) })
    } else {
      this.router.navigate(['/post']);
    }
  }

}
