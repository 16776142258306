import { Injectable } from '@angular/core';
import { Post } from '../post';
import { User } from '../user';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoragePostService {

  selectedPost: Post;
  allPosts: Post[];

  modalPost: Post;

  user: User;
  // URL: string = 'http://localhost:8080/yammer';
  URL: string = "http://stiridea.com:8080/yammer";

  constructor() { }

  getSelected() {
    return this.selectedPost;
  }

  getAllPosts() {
    return this.allPosts;
  }

  getUserName() {
    return this.user.userName;
  }

  getUserImage() {
    return this.user.userImagePath;
  }

  getUser() {
    return this.user;
  }

  private _subject = new Subject<any>();

  newEvent(event) {
    this._subject.next(event);
  }

  get events$() {
    return this._subject.asObservable();
  }
}
