export class User {
    user_id: number;
    userImagePath: string;
    firstName: string;
    lastName: string;
    email: string;
    coverImage: string;
    userName: string;
    type: string;
    modifiedDate;
    createdDate?: any;
    follow?: number;
    status: number;
}