import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { StoragePostService } from "../services/storage-post.service";
import { Opinion } from "../opinion";
import { PostService } from "../services/post.service";

@Injectable({
  providedIn: "root",
})
export class OpinionService {
  url = this.storageService.URL;

  constructor(
    private http: HttpClient,
    private storageService: StoragePostService,
    private postService: PostService
  ) {}

  getAllOpinions() {
    return this.http.get(this.url + "/opinion/all").pipe();
  }

  doLikeOnPost(opinion_id) {
    let like = {
      opinion_id: opinion_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/opinion/addLike", like).pipe();
  }

  doUnLikeOnPost(opinion_id: number) {
    let like = {
      opinion_id: opinion_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/opinion/removeLike", like).pipe();
  }

  doLikeOnComment(comment_id: number, opinion_id: number) {
    let commentsLike = {
      opinion_id: opinion_id,
      opinionComment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/opinion/comment/addLike", commentsLike)
      .pipe();
  }

  doUnLikeOnComment(comment_id: number, opinion_id: number) {
    let commentsLike = {
      opinion_id: opinion_id,
      opinionComment_id: comment_id,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/opinion/comment/removeLike", commentsLike)
      .pipe();
  }

  doCommentOnPost(opinion_id: number, content: string) {
    let comment = {
      opinion_id: opinion_id,
      content: content,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http.post(this.url + "/opinion/addComment", comment).pipe();
  }

  submitAnswer(opinion_id, answer) {
    let opinionAnswer = {
      opinion_id: opinion_id,
      answer: answer,
      user_id: localStorage.getItem("cUserId"),
    };
    return this.http
      .post(this.url + "/opinion/submitAnswer", opinionAnswer)
      .pipe();
  }

  getAllPopularOpinions() {}

  deleteOpinion(post: Opinion) {
    return this.http.get(
      this.url + "/opinion/deleteOpinion/" + post.opinion_id
    );
  }

  updateOpinion(post: Opinion) {
    let opinion = {
      opinion_id: post.opinion_id,
      content: post.content,
      isActive: post.isActive,
      opinionImage: post.opinionImage,
    };
    return this.http.post(this.url + "/opinion/updateOpinion", opinion);
  }

  async uploadOpinion(files: FileList, content: string) {
    var imagePath = "";
    if (files) {
      await this.postService.uploadImage(files[0]).then((data: any) => {
        imagePath = data.fileName;
      });
    }
    let post = {
      opinionImage: imagePath,
      content: content
    };
    return this.http.post(this.url + "/opinion/uploadOpinion", post).toPromise();
  }
}
