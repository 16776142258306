import { Component, OnInit } from "@angular/core";
import { Group } from "../../group";
import { GroupService } from "../../services/group.service";
import { Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { PrivacyForm } from "src/app/settings/models/privacyForm";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.css"],
})
export class GroupsComponent implements OnInit {
  allGroups: Group[] = [];
  allGrp: Group[] = [];
  tabs: number = 1;
  showCreate: boolean = false;
  groupTitle: string;
  subTitle: string;
  type: string = "all";
  fileList: FileList;
  image: boolean = false;

  userPrivacy: PrivacyForm = new PrivacyForm();

  view = 0;

  constructor(
    private groupService: GroupService,
    private router: Router,
    private spinner: Ng4LoadingSpinnerService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUserPrivacyDetails();
    this.getAllGroups();
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllGroups() {
    this.groupService.getAllGroupsForUser().subscribe(
      (data) => {
        this.allGroups = data as any;
        this.allGrp = data as any;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  showAllGroups() {
    this.tabs = 1;
    this.view = 0;
    this.allGroups = JSON.parse(JSON.stringify(this.allGrp));
  }

  showMyGroups() {
    this.tabs = 2;
    this.view = 0;
    this.allGroups = [];
    this.allGroups = this.allGrp.filter((group) => group.follow == 1);
  }

  showFavGroups() {
    this.view = 0;
    this.allGroups = [];
    this.allGroups = JSON.parse(JSON.stringify(this.allGrp.sort((a, b) => b.memberSize - a.memberSize)));
    this.allGroups = this.allGroups.splice(0, 10);
  }

  showPersonalGroups() {
    this.tabs = 3;
    this.groupService.getAllMyPersonalGroups().subscribe(
      (data) => {
        this.allGroups = data as Group[];
      },
      (err) => {
        console.log("Error in getting personal groups", err);
      }
    );
  }

  showCreateGroup() {
    this.showCreate = true;
    this.view = 3;
  }

  hideCreateGroup() {
    this.showCreate = false;
  }

  joinGroup(group_id: number) {
    this.groupService.joinGroup(group_id).subscribe(
      (data) => {
        this.allGroups = data as any;
        this.allGrp = data as any;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    (document.getElementById(
      "imageTitle"
    ) as HTMLInputElement).innerHTML = file;
    this.image = true;
  }

  addGroup() {
    this.spinner.show();
    if (this.image) {
      this.uploadWithImage();
    } else {
      this.uploadWithoutImage();
    }
  }

  uploadWithImage() {
    let file: File = this.fileList[0];
    this.groupService
      .addGroup(file, this.groupTitle, this.subTitle, this.type)
      .subscribe(
        (data) => {},
        (error) => {
          console.log(error);
        },
        () => {
          this.spinner.hide();
          window.location.reload();
        }
      );
  }

  uploadWithoutImage() {
    this.groupService
      .addGroupWithoutImage(this.groupTitle, this.subTitle, this.type)
      .subscribe(
        (data) => {
          this.spinner.hide();
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelectionChange(value) {
    this.type = value;
  }

  seeGroupInfo(index) {
    let group = this.allGroups[index];
    localStorage.setItem("selectedGroup", JSON.stringify(group));
    this.router.navigate(["/group-info"]);
  }

  leaveGroup(group_id: number) {
    this.groupService.leaveGroup(group_id).subscribe(
      (data) => {
        if (data) {
          let selGroup: Group;
          this.allGroups.forEach((group) => {
            if (group.group_id == group_id) {
              group.follow = 0;
              group.memberSize -= 1;
              selGroup = group;
            }
          });
          if (this.tabs == 2) {
            this.allGroups.splice(this.allGroups.indexOf(selGroup), 1);
          }
        }
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }
}
