import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { Post } from "../../post";
import { PostService } from "../../services/post.service";
import { Router } from "@angular/router";
import { Parameter } from "src/app/parameter";
import { UserService } from "src/app/services/user.service";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-popular-posts",
  templateUrl: "./popular-posts.component.html",
  styleUrls: ["./popular-posts.component.css"],
})
export class PopularPostsComponent implements OnInit, OnChanges {
  @Input() pageName;
  @Input() user_id;

  @Input() params: Parameter;

  popularPosts: Post[] = [];
  objectKeys = Object.keys;
  popularPostsIds: Number[];

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private postService: PostService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.user_id) {
      this.user_id = localStorage.getItem("cUserId");
    }
    if (this.params == undefined) {
      this.params = JSON.parse(sessionStorage.getItem("para"));
    }
    this.getUserPrivacyDetails();
    this.getAllPopularPostsByPageName(this.pageName);
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllPopularPostsByPageName(event) {
    this.popularPosts = [];
    this.postService
      .getAllPopularPostsByPageName(event, this.user_id, this.params)
      .subscribe(
        (data: Post[]) => {
          this.popularPosts = this.dataMassaging(data);
        },
        (err) => {
          console.log("Error in fetching popular posts", err);
        }
      );
  }

  dataMassaging(data) {
    data.forEach((post) => {
      let imagePath: string = post.imagePath;
      if (imagePath && imagePath.lastIndexOf("http://") > 10) {
        post.multipleImages = true;
        post.imagesList = imagePath.split(
          "http://stiridea.com:8080/applications/"
        );
        post.imagesList = post.imagesList.splice(1, post.imagesList.length);
        post.imagePath =
          "http://stiridea.com:8080/applications/" + post.imagesList[1];
      }
    });
    return data;
  }

  openPost(indexOfPost: number) {
    localStorage.setItem("pageName", this.pageName);
    // localStorage.setItem('popular', JSON.stringify(this.popularPosts));
    // this.storagePostService.selectedPost = this.popularPosts[indexOfPost];
    localStorage.setItem(
      "currentPost",
      JSON.stringify(this.popularPosts[indexOfPost])
    );
    sessionStorage.setItem("para", JSON.stringify(this.params));
    if (window.location.href === "http://stiridea.com/#/post") {
      this.router.navigate(["/aboutUs"]).then(() => {
        this.router.navigate(["/post"]);
      });
    } else {
      this.router.navigate(["/post"]);
    }
  }

  ngOnChanges() {
    this.getAllPopularPostsByPageName(this.pageName);
  }
}
