import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PostService } from 'src/app/services/post.service';
import { User } from 'src/app/user';
import { Post } from 'src/app/post';
import { Like } from 'src/app/like';
import { CommentsLike } from 'src/app/commentsLike';
import { UserService } from 'src/app/services/user.service';
import { StoragePostService } from 'src/app/services/storage-post.service';
import { Location } from '@angular/common';
import { BlogList } from 'src/app/blogList';

@Component({
  selector: 'app-my-blog',
  templateUrl: './my-blog.component.html',
  styleUrls: ['./my-blog.component.css']
})
export class MyBlogComponent implements OnInit {

  showDelete: boolean = false;
  cUserId;
  currentUser: User;
  blogDiaryUser;

  allPosts: Post[] = [];
  somePosts: Post[] = [];

  i: number = 3;
  j: number = 3;
  objectKeys = Object.keys;

  subject: String = '';
  feedback: String = '';
  feedbackPost: Post;

  bannerImage;
  bannerImageWidth;
  bannerImageHeight;

  modalPost: Post = new Post();

  @ViewChild('commentsModal') commentsModal;

  pageName: string = '';

  blogList: BlogList;

  constructor(private router: Router, private spinnerService: Ng4LoadingSpinnerService, private location: Location,
    private postService: PostService, private userService: UserService, private storageService: StoragePostService) {
    this.blogDiaryUser = JSON.parse(localStorage.getItem('blog-diary'));
    if (this.location.path(false) === '/myBlogs') {
      this.pageName = 'blogs';
    } else {
      this.pageName = 'diary';
    }
    this.cUserId = localStorage.getItem('cUserId');
    if (this.blogDiaryUser == this.cUserId) {
      this.showDelete = true;
    }
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.bannerImageWidth = window.innerWidth * .5;
    this.bannerImageHeight = window.innerHeight * .25;
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {

      this.j = this.j + 3;
      if (this.j >= this.allPosts.length) {
        this.j = this.allPosts.length;
      }
      for (this.i; this.i < this.j; this.i++) {
        this.somePosts.push(this.allPosts[this.i]);
      }

    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }

  ngOnInit() {
    this.getBlogList();
    // this.getBannerImage();
    this.getAllMyBlogs();
  }

  getBlogList() {
    this.postService.getSingleBlogList(sessionStorage.getItem('blogList')).subscribe(
      (data: BlogList) => {
        this.blogList = data;
      }, err => {
        console.log(err);
      }
    );
  }

  getBannerImage() {
    this.userService.getBannerImage(this.cUserId, 'blog').subscribe(
      data => {
        this.bannerImage = data;
      }, err => {
        console.log('Error in getting banner', err);
      }
    );
  }

  getAllMyBlogs() {
    this.postService.getAllBlogsOfUser(this.blogDiaryUser, 'blogs').subscribe(data => {
      this.allPosts = data as any;
      this.backup = JSON.parse(JSON.stringify(data));
      this.somePosts = this.allPosts.slice(0, this.i);
    },
      error => {
        console.log("Error in fetching data", error);
      }
    );
  }

  fileList: FileList;
  image: boolean = false;
  postContent: string;

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    ((document.getElementById("imageTitle") as HTMLInputElement)).innerHTML = file;
    this.image = true;
  }

  uploadPost() {
    this.spinnerService.show();
    if (this.image) {
      this.uploadWithImage();
    } else {
      this.uploadWithoutImage();
    }
  }

  uploadWithImage() {
    let file: File = this.fileList[0];
    this.postService.addPostInBlogs(file, this.postContent, 'blogs').subscribe(
      data => {
        console.log('success');
      },
      error => {
        console.log(error)
      },
      () => {
        this.spinnerService.hide();
        this.router.navigate(['/aboutUs'])
          .then(() => { this.router.navigate(['/myBlogs']) })
      }
    )
  }

  uploadWithoutImage() {
    this.postService.addPostWithoutImageInBlogs(this.postContent, 'blogs').subscribe(
      data => {
      },
      error => {
        console.log(error)
      }, () => {
        this.spinnerService.hide();
        this.router.navigate(['/aboutUs'])
          .then(() => { this.router.navigate(['/myBlogs']) })
      }
    );
  }

  checkLike(likes: Like[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doLikePost(post_id: number) {
    this.postService.doLikeOnBlogs(post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        // this.toggleLike = true;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = Apost;
          }
        }
      },
      error => {
        console.log("error in like");
      }
    )
  }

  doUnLikePost(post_id: number) {
    this.postService.doUnLikeOnBlogs(post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = Apost;
          }
        }
      },
      error => {
        console.log("error in Unlike", error);
      }
    )
  }

  doLikeComment(comment_id: number, post_id: number) {
    this.postService.doLikeOnCommentOnBlogs(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = Apost;
          }
        }
      },
      error => {
        console.log("error in like on comment", error);
      }
    )
  }

  doUnLikeComment(comment_id: number, post_id) {
    this.postService.doUnLikeOnCommentOnBlogs(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = Apost;
          }
        }
      },
      error => {
        console.log("error in Unlike on Comment", error);
      }
    )
  }

  doCommentOnPost(post_id: number) {
    var newComment = ((document.getElementById("newComment" + post_id) as HTMLInputElement).value);
    this.postService.doCommentOnPostOnBlogs(post_id, newComment).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = Apost;
          }
        }
      },
      error => {
        console.log("Error in Comments ", error);
      }
    )
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  focusReply(id) {
    document.getElementById('newComment' + id).focus();
  }

  shareOnFB(post: Post) {
    let url = 'stiridea.com%2F%23sharedPost%2F' + post.post_id;
    window.open("https://www.facebook.com/share.php?u=" + url);
  }

  shareOnTwitter(post: Post) {
    window.open("https://twitter.com/share?original_referer=/&text=" + post.content + "&url=" + post.imagePath);
  }

  shareOnGoogle(post: Post) {
    window.open('https://plus.google.com/share?url=' + post.imagePath);
  }

  shareOnLinkedin(post: Post) {
    window.open('https://www.linkedin.com/cws/share?url=' + post.imagePath);
  }

  textAreaEmpty(event, postId) {
    var newComment = ((document.getElementById("newComment" + postId) as HTMLInputElement).value);
    var button = document.getElementById("button" + postId) as HTMLButtonElement;
    if (newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  editablePost: Post = new Post();
  newContent: string = '';

  editPost(post: Post) {
    this.editablePost = post;
    this.newContent = post.content;
    document.getElementById('editPostButton').click();
  }

  updatePost() {
    this.editablePost.content = this.newContent;
    this.postService.updateBlogs(this.editablePost).subscribe(
      response => {
      }, err => {
        console.log('Error in Update', err);
      }
    );
  }

  deletePost(postId, index) {
    if (confirm("Are you sure to delete post ?")) {
      this.postService.deleteBlogs(postId).subscribe(
        data => {
          console.log(data);
        }, err => {
          console.log('Error in delete', err);
        }, () => {
          this.somePosts.splice(index, 1);
        }
      );
    }
  }

  backup: Post[] = [];

  dateSelected(event) {
    var date: Date = event.value;
    this.allPosts = [];
    for (let index = 0; index < this.backup.length; index++) {
      var postDate = new Date(this.backup[index].uploadDate);
      if (postDate.getDate() == date.getDate() && postDate.getFullYear() == date.getFullYear() && postDate.getMonth() == date.getMonth()) {
        this.allPosts.push(this.backup[index]);
      }
    }

    this.somePosts = [];
    this.somePosts = this.allPosts.slice(0, this.i);
  }

  hidePost(post) {
    let index = this.somePosts.indexOf(post);
    this.somePosts.splice(index, 1);
  }

  savePost(post) {
    this.postService.savePost(post).subscribe(
      res => {
        if (res) {
          window.alert('Post saved successfully!');
        } else {
          window.alert('Something went wrong.');
        }
      }, err => {
        console.log('Error while saving post', err);
      }
    );
  }

  feeback(post) {
    this.subject = '';
    this.feedback = '';
    this.feedbackPost = post;
    document.getElementById('feedbackButton').click();
  }

  sendFeedback() {
    let feedback = {
      'subject': this.subject,
      'feedback': this.feedback,
      'post': this.feedbackPost,
      'userId': this.cUserId
    }
    this.userService.sendFeedback(feedback).subscribe(
      res => {
        console.log(res);
      }
    );
  }

  fileChangeForCover(event) {
    this.fileList = event.target.files;
    this.spinnerService.show();
    this.uploadCoverImage();
  }

  uploadCoverImage() {
    let file: File = this.fileList[0];
    this.userService.uploadBannerImage(file, this.cUserId, 'blog').subscribe(
      data => {
        this.bannerImage = data;
        this.spinnerService.hide();
      },
      error => {
        console.log(error);
      }
    )
  }

  likesModalHeading: string = 'Likes';
  likedByUsers: User[] = [];

  showLikesModal(heading, post: Post) {
    if (heading == 'Likes') {
      this.userService.getUsersWhoLikedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    } else {
      this.userService.getUsersWhoSharedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    }
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(post: Post) {
    if (post.imagePath) {
      if (post.multipleImages) {
        window.open('http://stiridea.com:8080/applications/' + post.imagesList[0]);
      } else {
        window.open(post.imagePath);
      }
    }
  }

  openCommentsModal(post: Post) {
    this.modalPost = post;
  }

  commentLikeEvent(event) {
    let Apost = this.storageService.modalPost;
    for (let post of this.somePosts) {
      if (post.post_id == Apost.post_id) {
        var index = this.somePosts.indexOf(post);
        // this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
        this.somePosts[index] = Apost;
        break;
      }
    }
  }

}
