import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { User } from "../../user";
import { UserService } from "src/app/services/user.service";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  image: boolean;
  notify: boolean = false;
  shake = "no";
  currentPage = 'home';

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private router: Router,
    private location: Location,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.currentPage = this.location.path(false);
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    if (this.currentUser) {
      this.getUserPrivacy();
    } else {
      this.userPrivacy.selectedColor = "#f90606";
    }
    if (this.currentUser) {
      this.image = true;
      this.checkForNotifications();
    } else {
      this.image = false;
    }
  }

  getUserPrivacy() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  checkForNotifications() {
    this.userService
      .checkForNewNotifications(this.currentUser.user_id)
      .subscribe(
        (res) => {
          this.notify = res as boolean;
        },
        (err) => {
          console.log("Error while checking notifications ", err);
        },
        () => {
          if (this.notify) {
            this.shake = "shake";
            setTimeout(() => {
              this.shake = "no";
            }, 2000);
          }
        }
      );
  }

  doLogout() {
    if (confirm("Are you sure to logout ?")) {
      localStorage.clear();
      this.location.replaceState("/");
      this.router.navigate(["/"]);
    }
  }

  goToSettings() {
    this.router.navigate(["/settings"]);
  }

  goToSearch() {
    this.router.navigate(["/searchUser"]);
  }

  goToNotifications() {
    this.router.navigate(["/notifications"]);
  }

  navigate(address) {
    this.router.navigate(["/settings" + address]);
  }
}
