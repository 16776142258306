export class UserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  contact?: number;
  address?: string;

  dateOfBirth?: string;
  birthPlace?: string;
  sex?: string;
  relationshipStatus?: string;
  language?: string;
  country?: string;
  state?: string;
  city?: string;
  hometownAddress?: string;
  currentAddress?: string;

  schoolYear?: string;
  schoolName?: string;
  schoolLocation?: string;
  examBoard?: string;

  collegeName?: string;
  collegeLocation?: string;
  collegeExamBoard?: string;
  collegeYear?: string;

  university?: string;
  universityLocation?: string;
  phdYear?: string;
  phdUniversity?: string;

  industry?: string;
  specializeField?: string;
  skill?: string;
  designation?: string;

  emailPrivate?: boolean = false;
  contactPrivate?: boolean = true;
  addressPrivate?: boolean = true;

  dateOfBirthPrivate?: boolean;
  birthPlacePrivate?: boolean;
  sexPrivate?: boolean;
  relationshipStatusPrivate?: boolean;
  languagePrivate?: boolean;
  countryPrivate?: boolean;
  statePrivate?: boolean;
  cityPrivate?: boolean;
  hometownAddressPrivate?: boolean;
  currentAddressPrivate?: boolean;

  schoolPrivate?: boolean;
  collegePrivate?: boolean;
  universityPrivate?: boolean;

  specializationPrivate: boolean;
  skillsPrivate: boolean;

  status: string;
  presence: string;
}
