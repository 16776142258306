import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Post } from 'src/app/post';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { User } from 'src/app/user';
import { PostService } from 'src/app/services/post.service';
import { ModalDirective } from 'angular-bootstrap-md';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() modalPost: Post;

  @ViewChild('basicModal') basicModal: ModalDirective;

  @Input() pageName: string;

  modalPostType: string = 'all';
  modalSubCategory: string = 'all';
  category: any;
  shareContent: string;
  followers: User[];
  selectedFollowers: User[] = [];

  cUserId;

  constructor(private router: Router, private spinnerService: Ng4LoadingSpinnerService,
    private postService: PostService, private userService: UserService) { }


  ngOnInit() {
    this.cUserId = +localStorage.getItem('cUserId');
    this.getAllFollowers();
  }

  showModal() {
    this.basicModal.show();
  }

  checkForMoreImages(post) {
    if (post.imagesList && post.imagesList.length > 4) {
      return true;
    }
    return false;
  }

  openPost(post: Post) {
    localStorage.setItem('pageName', this.pageName);
    localStorage.setItem('currentPost', JSON.stringify(post));
    this.router.navigate(['/post']);
  }

  getAllFollowers() {
    this.userService.getAllFollowingsOfUser(this.cUserId).subscribe(
      data => {
        this.followers = data as User[];
      }, error => {
        console.log('Error in getting followers', error);
      }
    );
  }

  selectFollower(event, user) {
    if (event.target.checked) {
      this.selectedFollowers.push(user);
    } else {
      this.selectedFollowers.splice(this.selectedFollowers.indexOf(user), 1);
    }
  }

  doSharePost(post_id: number) {
    this.spinnerService.show();
    if (this.category == 3) {
      if (this.selectedFollowers.length > 0) {
        for (let index = 0; index < this.selectedFollowers.length; index++) {
          this.postService.doSharePostAsPersonal(post_id, this.shareContent, this.category,
            this.modalPostType, this.modalSubCategory, this.selectedFollowers[index].user_id, this.selectedFollowers[index].userName).subscribe(
              data => {
                this.spinnerService.hide();
                this.router.navigate(['/aboutUs'])
                  .then(() => { this.router.navigate(['/home']) });
              },
              error => {
                this.spinnerService.hide();
                console.log('Error in Sharing post', error);
              }
            )
        }
      } else {
        this.spinnerService.hide();
        alert('Please select atleast one user.');
      }
    } else {
      this.postService.doSharePost(post_id, this.shareContent, this.category, this.modalPostType, this.modalSubCategory).subscribe(
        data => {
          this.spinnerService.hide();
          this.router.navigate(['/aboutUs'])
            .then(() => { this.router.navigate(['/home']) });
        },
        error => {
          this.spinnerService.hide();
          console.log('Error in Sharing post', error);
        }
      )
    }
  }

  onSelectionChange(value) {
    this.category = value;
  }

  changeModalPostType(value) {
    this.modalPostType = value;
  }

  changeModalSubCategory(value) {
    this.modalSubCategory = value;
  }

  getBackgroundImage(post: Post) {
    return post.backImage ? "url('" + post.backImage + "')" : "none";
  }

  getBackgroundImageHeight(post: Post) {
    return post.backImage ? "20rem" : "1rem";
  }

}
