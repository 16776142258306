import { Component, OnInit, Input } from "@angular/core";
import { User } from "../../user";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-people-may-know",
  templateUrl: "./people-may-know.component.html",
  styleUrls: ["./people-may-know.component.css"],
})
export class PeopleMayKnowComponent implements OnInit {
  allUsers: User[];
  cUserId;

  @Input() screenName;

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(private userService: UserService, private router: Router) {
    this.cUserId = +localStorage.getItem("cUserId");
  }

  ngOnInit() {
    this.getUserPrivacyDetails();
    this.getAllUsers();
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllUsers() {
    this.userService.getAllUsersToFollow().subscribe(
      (data) => {
        this.allUsers = data as any;
        if (this.screenName == "public") {
          this.allUsers = this.allUsers.filter(
            (value) => value.type == "public"
          );
        } else {
          this.allUsers = this.allUsers.filter(
            (value) => value.type == "celebrity"
          );
        }
        this.allUsers.map((user) => (user.follow = 0));
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  followUser(userId) {
    this.userService.followUser(this.cUserId, userId).subscribe(
      (data) => {
        this.allUsers.forEach((user) => {
          if (user.user_id === userId) {
            user.follow = 1;
          }
        });
      },
      (error) => {
        console.log("Error in following", error);
      }
    );
  }

  unfollowUser(userId) {
    this.userService.unfollowUser(this.cUserId, userId).subscribe(
      (data) => {
        this.allUsers.forEach((user) => {
          if (user.user_id === userId) {
            user.follow = 0;
          }
        });
      },
      (error) => {
        console.log("Error in following", error);
      }
    );
  }

  showUserNetwork(user_id) {
    localStorage.setItem("selectedUser_id", user_id);
    this.router.navigate(["/showUserProfile"]);
  }

  goToUserSearch() {
    this.router.navigate(["/searchUser"]);
  }
}
