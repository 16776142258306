import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UserService } from "../../services/user.service";
import { UserInfo } from "../../userInfo";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.css"],
})
export class UserInfoComponent implements OnInit {
  userInfo: UserInfo = new UserInfo();

  enableButtonDivName: string = "reset";

  generalDisable: boolean = true;
  basicDisable: boolean = true;
  educationDisable: boolean = true;
  professionalDisable: boolean = true;

  @Output() valueChange = new EventEmitter();

  @Input() pageName: string;
  @Input() userId;
  showOptions: boolean = false;

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private userService: UserService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
  }

  ngOnInit() {
    if (this.pageName == "myNetwork") {
      this.showOptions = true;
    } else {
      this.showOptions = false;
    }
    this.getUserPrivacyDetails();
    this.getUserInfo();
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  toggle(divName) {
    this.enableButtonDivName = divName;
    if (divName == "general") {
      this.generalDisable = false;
    } else {
      this.generalDisable = true;
    }
    if (divName == "basic") {
      this.basicDisable = false;
    } else {
      this.basicDisable = true;
    }
    if (divName == "education") {
      this.educationDisable = false;
    } else {
      this.educationDisable = true;
    }
    if (divName == "professional") {
      this.professionalDisable = false;
    } else {
      this.professionalDisable = true;
    }
  }

  getUserInfo() {
    this.userService.getUserInfo(this.userId).subscribe(
      (data) => {
        this.userInfo = data as any;
      },
      (error) => {
        console.log("Error", error);
      }
    );
  }

  updateInfo() {
    this.spinnerService.show();
    this.userService.updateInfo(this.userInfo).subscribe(
      (data) => {
        this.userInfo = data as any;
        this.loggedInUSer();
        this.spinnerService.hide();
      },
      (error) => {
        console.log("Error", error);
        this.spinnerService.hide();
      },
      () => {
        this.toggle("reset");
      }
    );
  }

  loggedInUSer() {
    this.userService.getLoggedInUser().subscribe(
      (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        this.valueChange.emit();
      },
      (err) => {
        console.log("Error in update", err);
      }
    );
  }
}
