import { Component, OnInit, ViewChild } from '@angular/core';
import { PostService } from '../../services/post.service';
import { Post } from '../../post';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HostListener } from '@angular/core';
import { Like } from '../../like';
import { CommentsLike } from '../../commentsLike';
import { User } from '../../user';
import { UserService } from '../../services/user.service';
import { StoragePostService } from 'src/app/services/storage-post.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Parameter } from 'src/app/parameter';

@Component({
  selector: 'app-celebrity',
  templateUrl: './celebrity.component.html',
  styleUrls: ['./celebrity.component.css']
})
export class CelebrityComponent implements OnInit {

  // allPosts: Post[];
  somePosts: Post[] = [];
  i: number = 3;
  j: number = 3;
  objectKeys = Object.keys;
  newComment: string;
  cUserId: any;
  modalPost: Post = new Post();
  shareContent: string;

  dataLoaded: boolean = false;

  category: any;
  postType: string = 'all';
  subCategory: string;

  modalPostType: string = 'all';
  modalSubCategory: string = 'all';

  followers: User[];
  selectedFollowers: User[] = [];

  subject: String = '';
  feedback: String = '';
  feedbackPost: Post;

  viewedPosts: any[] = [];
  pageName: string = 'celebrity';

  limit: number = 10;
  params: Parameter = new Parameter();
  isPopularPosts = false;

  @ViewChild('modal') modal;
  @ViewChild('commentsModal') commentsModal;

  constructor(private postService: PostService, private router: Router, private spinnerService: Ng4LoadingSpinnerService,
    private location: Location, private userService: UserService, private storageService: StoragePostService) {
    if (!localStorage.getItem("user")) {
      location.replaceState('/');
      router.navigate(['/'])
    }
    this.cUserId = +localStorage.getItem('cUserId');
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached() && !this.isPopularPosts) {
      this.params.offset += this.limit;
      this.getAllPosts();
    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }

  ngOnInit() {
    this.somePosts = [];
    this.resetParams();
    this.getAllPosts();
    this.getAllFollowers();
  }

  checkLike(likes: Like[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  getAllPosts() {
    this.isPopularPosts = false;
    this.spinnerService.show();
    this.postService.getPostsByParams(this.params).subscribe(
      (data: Post[]) => {
        this.dataMassaging(data);
        this.spinnerService.hide();
        this.viewedPosts = data;
        this.updateViewPosts();
      },
      error => {
        console.log("Error in fetching data", error);
        this.spinnerService.hide();
      }
    );
  }

  dataMassaging(data) {
    data.forEach((post) => {
      let imagePath: string = post.imagePath;
      if (imagePath && imagePath.lastIndexOf('http://') > 10) {
        post.multipleImages = true;
        post.imagesList = imagePath.split('http://stiridea.com:8080/applications/');
        post.imagesList = post.imagesList.splice(1, post.imagesList.length);
      }
      post.comments = post.comments.sort((a, b) => b.comment_id - a.comment_id);
      this.somePosts.push(post);
    });
  }

  checkForMoreImages(post) {
    if (post.imagesList && post.imagesList.length > 4) {
      return true;
    }
    return false;
  }

  openPost(post: Post) {
    localStorage.setItem('pageName', this.pageName);
    localStorage.setItem('currentPost', JSON.stringify(post));
    this.router.navigate(['/post']);
  }

  updateViewPosts() {
    if (this.viewedPosts.length > 0) {
      let viewIds: string = '';
      this.viewedPosts.forEach((post: Post) => {
        viewIds += post.post_id + ',';
      });
      this.postService.updatePostsView(viewIds).subscribe(
        res => {

        }, err => {
          console.log(err);
        }
      );
    }
  }

  doSharePost(post_id: number) {
    this.spinnerService.show();
    if (this.category == 3) {
      if (this.selectedFollowers.length > 0) {
        for (let index = 0; index < this.selectedFollowers.length; index++) {
          this.postService.doSharePostAsPersonal(post_id, this.shareContent, this.category, this.modalPostType,
            this.modalSubCategory, this.selectedFollowers[index].user_id, this.selectedFollowers[index].userName).subscribe(
              data => {
                this.selectedFollowers = [];
                this.spinnerService.hide();
                this.router.navigate(['/aboutUs'])
                  .then(() => { this.router.navigate(['/celebrity']) });
              },
              error => {
                this.spinnerService.hide();
                console.log('Error in Sharing post', error);
              }
            )
        }
      } else {
        this.spinnerService.hide();
        alert('Please select atleast one user.');
      }
    } else {
      this.postService.doSharePost(post_id, this.shareContent, this.category, this.modalPostType, this.modalSubCategory).subscribe(
        data => {
          this.spinnerService.hide();
          this.router.navigate(['/aboutUs'])
            .then(() => { this.router.navigate(['/celebrity']) });
        },
        error => {
          this.spinnerService.hide();
          console.log('Error in Sharing post', error);
        }
      )
    }
  }

  sharePost(postIndex: number) {
    this.modalPost = this.somePosts[postIndex];
  }

  dataMassagingOfSinglePost(post: Post) {
    let imagePath: string = post.imagePath;
    if (imagePath && imagePath.lastIndexOf('http://') > 10) {
      post.multipleImages = true;
      post.imagesList = imagePath.split('http://stiridea.com:8080/applications/');
      post.imagesList = post.imagesList.splice(1, post.imagesList.length);
    }
    post.comments = post.comments.sort((a, b) => b.comment_id - a.comment_id);
    return post;
  }

  doLikePost(post_id: number) {
    this.postService.doLikeOnPost(post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      error => {
        console.log('Error in like', error);
      }
    )
  }

  doUnLikePost(post_id: number) {
    this.postService.doUnLikeOnPost(post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      error => {
        console.log('Error in unlike', error);
      }
    )
  }

  doLikeComment(comment_id: number, post_id: number) {
    this.postService.doLikeOnComment(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
            this.modalPost = this.somePosts[index];
          }
        }
      },
      error => {
        console.log("error in like on comment", error);
      }
    )
  }

  doUnLikeComment(comment_id: number, post_id) {
    this.postService.doUnLikeOnComment(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
            this.modalPost = this.somePosts[index];
          }
        }
      },
      error => {
        console.log("error in Unlike on Comment", error);
      }
    )
  }

  doCommentOnPost(post_id: number) {
    var newComment = ((document.getElementById("newComment" + post_id) as HTMLInputElement).value);
    this.postService.doCommentOnPost(post_id, newComment).subscribe(
      data => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      error => {
        console.log("Error in Comments ", error);
      }
    )
  }

  resetParams() {
    this.params = new Parameter();
    this.params.offset = 0;
    this.params.category = 0;
    this.params.userType = 'celebrity';
  }

  changePostsType(value) {
    this.postType = value;
    this.somePosts = [];
    this.resetParams();
    this.params.postType = value;
    this.getAllPosts();
    // this.getDataAsPerPostType();

  }

  // getDataAsPerPostType() {
  //   this.postService.getDataAsPerPostType('celebrity', this.postType).subscribe(
  //     data => {
  //       this.allPosts = [];
  //       this.allPosts = data as any;
  //       this.backup = JSON.parse(JSON.stringify(this.allPosts));
  //       this.storageService.newEvent('changed');
  //       this.somePosts = this.allPosts.slice(0, this.i);
  //     },
  //     error => {
  //       console.log("Error in fetching data", error);
  //     }
  //   );
  // }

  changeSubCategory(value) {
    this.subCategory = value;
    this.somePosts = [];
    this.params.offset = 0;
    this.params.subCategory = value;
    this.getAllPosts();
    // this.getDataAsPerSubCategory();
  }

  // getDataAsPerSubCategory() {
  //   this.postService.getDataAsPerSubCategory('celebrity', this.postType, this.subCategory).subscribe(
  //     data => {
  //       this.allPosts = data as any;
  //       this.backup = JSON.parse(JSON.stringify(this.allPosts));
  //       this.somePosts = this.allPosts.slice(0, this.i);
  //     },
  //     error => {
  //       console.log("Error in fetching data", error);
  //     }
  //   );
  // }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  focusReply(id) {
    document.getElementById('newComment' + id).focus();
  }

  onSelectionChange(value) {
    this.category = value;
  }

  changeModalPostType(value) {
    this.modalPostType = value;
  }

  changeModalSubCategory(value) {
    this.modalSubCategory = value;
  }

  getAllFollowers() {
    this.userService.getAllFollowingsOfUser(this.cUserId).subscribe(
      data => {
        this.followers = data as User[];
      }, error => {
        console.log('Error in getting followers', error);
      }
    );
  }

  selectFollower(event, user) {
    if (event.target.checked) {
      this.selectedFollowers.push(user);
    } else {
      this.selectedFollowers.splice(this.selectedFollowers.indexOf(user), 1);
    }
  }

  hidePost(post) {
    let index = this.somePosts.indexOf(post);
    this.somePosts.splice(index, 1);
  }

  savePost(post) {
    this.postService.savePost(post).subscribe(
      res => {
        if (res) {
          window.alert('Post saved successfully!');
        } else {
          window.alert('Something went wrong.');
        }
      }, err => {
        console.log('Error while saving post', err);
      }
    );
  }

  feeback(post) {
    this.subject = '';
    this.feedback = '';
    this.feedbackPost = post;
    document.getElementById('feedbackButton').click();
  }

  sendFeedback() {
    let feedback = {
      'subject': this.subject,
      'feedback': this.feedback,
      'post': this.feedbackPost,
      'userId': this.cUserId
    }
    this.userService.sendFeedback(feedback).subscribe(
      res => {
        console.log(res);
      }
    );
  }

  shareOnFB(post: Post) {
    let url = 'stiridea.com%2F%23sharedPost%2F' + post.post_id;
    window.open("https://www.facebook.com/share.php?u=" + url);
  }

  shareOnTwitter(post: Post) {
    window.open("https://twitter.com/share?original_referer=/&text=" + post.content + "&url=" + post.imagePath);
  }

  shareOnGoogle(post: Post) {
    window.open('https://plus.google.com/share?url=' + post.imagePath);
  }

  shareOnLinkedin(post: Post) {
    window.open('https://www.linkedin.com/cws/share?url=' + post.imagePath);
  }

  // backup: Post[] = [];

  dateSelected(event) {
    var date: Date = event.value;
    this.somePosts = [];
    this.resetParams();
    this.params.uploadDate = this.formatDate(date);
    this.getAllPosts();
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  textAreaEmpty(event, postId) {
    var newComment = ((document.getElementById("newComment" + postId) as HTMLInputElement).value);
    var button = document.getElementById("button" + postId) as HTMLButtonElement;
    if (newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  likesModalHeading: string = 'Likes';
  likedByUsers: User[] = [];

  showLikesModal(heading, post: Post) {
    if (heading == 'Likes') {
      this.userService.getUsersWhoLikedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    } else {
      this.userService.getUsersWhoSharedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        }, err => {
          console.log('Error in fetching users', err);
        }
      );
    }
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(post: Post) {
    if (post.imagePath) {
      if (post.multipleImages) {
        window.open('http://stiridea.com:8080/applications/' + post.imagesList[0]);
      } else {
        window.open(post.imagePath);
      }
    }
  }

  followUser(user_id) {
    this.userService.followUser(this.cUserId, user_id).subscribe(
      data => {
        this.somePosts.forEach((post) => {
          if (post.user_id == user_id) {
            post.follow = 1;
          }
        })
      }, error => {
        console.log('Error in following', error);
      }
    );
  }

  unfollowUser(user_id) {
    if (confirm("Are you sure to unfollow ?")) {
      this.userService.unfollowUser(this.cUserId, user_id).subscribe(
        data => {
          this.somePosts.forEach((post) => {
            if (post.user_id == user_id) {
              post.follow = 0;
            }
          })
        }, error => {
          console.log('Error in following', error);
        }
      );
    }
  }

  searchString: string = '';

  filterPost() {
    this.somePosts = [];
    this.resetParams();
    this.params.searchString = this.searchString;
    this.getAllPosts();
  }

  showTriangle: boolean = false;
  showUsersPosts(userId) {
    this.somePosts = [];
    this.resetParams();
    this.params.user_id = userId;
    this.showTriangle = true;
    this.getAllPosts();
  }

  openCommentsModal(post: Post) {
    this.modalPost = post;
  }

  commentLikeEvent(event) {
    let Apost = this.storageService.modalPost;
    for (let post of this.somePosts) {
      if (post.post_id == Apost.post_id) {
        var index = this.somePosts.indexOf(post);
        this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
        break;
      }
    }
  }

  showMessage(message: string) {
    window.alert(message);
  }

  goBack() {
    this.somePosts = [];
    this.showTriangle = false;
    this.resetParams();
    this.getAllPosts();
  }

  getPopularPosts() {
    this.isPopularPosts = true;
    this.spinnerService.show();
    this.resetParams();
    this.somePosts = [];
    this.postService
      .getAllPopularPostsByPageName(this.pageName, this.cUserId, this.params)
      .subscribe(
        (data: Post[]) => {
          this.dataMassaging(data);
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();
          console.log(err);
        }
      );
  }

  getBackgroundImage(post: Post) {
    return post.backImage ? "url('" + post.backImage + "')" : "none";
  }

  getBackgroundImageHeight(post: Post) {
    return post.backImage ? "35rem" : "1rem";
  }

}
