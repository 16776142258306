import { Comment } from "./comment";
import { Time } from "@angular/common";
import { Like } from "./like";

export class Post {
    post_id: number;
    content: string;
    uploadTime: Time;
    uploadDate: Date;
    user_id: number;
    comments: Comment[];
    imagePath: string;
    userImagePath: string;
    userName: string;
    likes: Like[];
    shareContent: string;
    postStatus: number;
    category: number;
    postType: string;
    subCategory: string;
    prevPostUser: string;
    prevPostUserId: number;
    personallySharedBy: string;
    personallySharedById: string;
    groupId?: number;
    shareCount?: number;
    viewCount?: number;
    follow?: number;
    postCount?: number = 0;

    multipleImages?: boolean;
    imagesList?: any[];

    isEdit?: boolean = false;
    isActive?: number = 0;

    backColor: string;
    backImage: string;
}