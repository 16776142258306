import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { Post } from 'src/app/post';
import { CommentsLike } from 'src/app/commentsLike';
import { Router } from '@angular/router';
import { PostService } from 'src/app/services/post.service';
import { StoragePostService } from 'src/app/services/storage-post.service';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.css']
})
export class CommentsModalComponent implements OnInit, OnChanges {

  @Input() modalPost: Post = new Post();
  @Input() cUserId;

  @ViewChild('commentsModal') commentsModal;

  @Output() commentLikeEvent = new EventEmitter();

  objectKeys = Object.keys;
  showNoCommentsMsg: boolean = false;
  count: number = 0;

  constructor(private router: Router, private postService: PostService,
    private storagePostService: StoragePostService) {
  }

  ngOnInit() {
  }

  showModal() {
    this.commentsModal.show();
  }

  ngOnChanges() {
    if (this.modalPost.comments && this.modalPost.comments.length > 0) {
      this.showNoCommentsMsg = false;
      this.count = this.modalPost.comments.length;
    } else {
      this.showNoCommentsMsg = true;
      this.count = 0;
    }
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id === this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doLikeComment(comment_id: number, post_id: number) {
    this.postService.doLikeOnComment(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        this.storagePostService.modalPost = Apost;
        this.commentLikeEvent.emit(JSON.stringify(post_id));
        this.modalPost = JSON.parse(JSON.stringify(Apost));
      },
      error => {
        console.log("error in like on comment");
        this.commentLikeEvent.emit('l/' + JSON.stringify(comment_id));
      }
    )
  }

  doUnLikeComment(comment_id: number, post_id) {
    this.postService.doUnLikeOnComment(comment_id, post_id).subscribe(
      data => {
        let Apost: Post = data as any;
        this.storagePostService.modalPost = Apost;
        this.commentLikeEvent.emit(JSON.stringify(post_id));
        this.modalPost = Apost;
      },
      error => {
        console.log("error in Unlike on Comment", error);
        this.commentLikeEvent.emit('u/' + JSON.stringify(comment_id));
      }
    )
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

}
