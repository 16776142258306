import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OpinionService } from "../../service/opinion.service";
import { Opinion } from "../../opinion";
import { UserService } from "src/app/services/user.service";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-popular-opinion",
  templateUrl: "./popular-opinion.component.html",
  styleUrls: ["./popular-opinion.component.css"],
})
export class PopularOpinionComponent implements OnInit {
  popularPosts: Opinion[] = [];
  objectKeys = Object.keys;

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private opinionService: OpinionService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUserPrivacyDetails();
    this.getAllPopularPosts();
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllPopularPosts() {
    this.opinionService.getAllOpinions().subscribe(
      (data: Opinion[]) => {
        data = data.filter((op) => op.isActive === 1);
        this.popularPosts = data as any;
        this.sortList();
      },
      (error) => {
        console.log("Error in fetching popular opinions", error);
      }
    );
  }

  sortList() {
    this.popularPosts = this.popularPosts.sort(
      (a, b) => b.opinioncomments.length - a.opinioncomments.length
    );
  }

  openPost(indexOfPost: number) {
    localStorage.setItem(
      "selectedOpinion",
      JSON.stringify(this.popularPosts[indexOfPost])
    );
    // if (window.location.href === 'http://localhost:4200/#/oneOpinion') {
    if (window.location.href === "http://stiridea.com/#/oneOpinion") {
      this.router.navigate(["/aboutUs"]).then(() => {
        this.router.navigate(["/oneOpinion"]);
      });
    } else {
      this.router.navigate(["/oneOpinion"]);
    }
  }
}
