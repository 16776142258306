import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { User } from 'src/app/user';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit, OnChanges {

  @Input()
  heading: string;

  @Input()
  users: User[] = [];

  cUserId;
  number;

  constructor(private router: Router, private userService: UserService) {
    this.cUserId = +localStorage.getItem('cUserId');
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.number = this.users.length;
  }

  showUserNetwork(user_id) {
    document.getElementById('closeButton').click();
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  followUser(user_id) {
    this.userService.followUser(this.cUserId, user_id).subscribe(
      data => {
        window.location.reload();
      }, error => {
        console.log('Error in following', error);
      }
    );
  }

  unfollowUser(user_id) {
    this.userService.unfollowUser(this.cUserId, user_id).subscribe(
      data => {
        window.location.reload();
      }, error => {
        console.log('Error in following', error);
      }
    );
  }

}
