export class Parameter {

    content?: string;
    uploadDate?: string;
    user_id?: number;
    postStatus?: number;
    category?;
    userType?: string;
    postType?: string;
    subCategory?: string;
    prevPostUser?: string;
    prevPostUserId?: number;
    personallySharedBy?: string;
    groupId?: number;
    offset?: number = 0;
    searchString?: string;
}