import { Component, OnInit } from '@angular/core';
import { Post } from '../../post';

@Component({
  selector: 'app-dateandeventsingle',
  templateUrl: './dateandeventsingle.component.html',
  styleUrls: ['./dateandeventsingle.component.css']
})
export class DateandeventsingleComponent implements OnInit {

  ImpEvent: Post;

  constructor() {
    this.ImpEvent = JSON.parse(localStorage.getItem('impEvent'));
   }

  ngOnInit() {
  }

}
