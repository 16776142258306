import { Like } from "./like";
import { Comment } from "./comment";

export class BlogList {
    blogId?: number;
    title?: string;
    subTitle?: string;
    imagePath?: string;
    userId?: number;
    content?: string;
    createdDate?: Date;
    pageType?: string;
    likes?: Like[] = [];
    comments?: Comment[] = [];
    subscribed?: number;
    visibility?: string;
    viewCount?: number;
}