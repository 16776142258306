import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from 'src/app/notification';
import { PostService } from 'src/app/services/post.service';
import { Post } from 'src/app/post';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  allNotifications: Notification[] = [];
  cUserId;

  post: Post;

  constructor(private location: Location, private router: Router, private spinnerService: Ng4LoadingSpinnerService,
    private notificationService: NotificationService, private postService: PostService) {
    if (!localStorage.getItem("user")) {
      location.replaceState('/');
      router.navigate(['/'])
    }
    this.cUserId = +localStorage.getItem('cUserId');
  }

  ngOnInit() {
    this.getAllNotifications();
  }

  getAllNotifications() {
    this.notificationService.getAllNotifications(this.cUserId).subscribe(
      data => {
        this.dataMassaging(data);
      }, err => {
        console.log('Error in getting notifications', err);
      }
    );
  }

  showUsersNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  openNotification(notification: Notification) {
    this.spinnerService.show();
    this.notificationService.updateStatus(notification.notificationId).subscribe(
      res => {
        if (notification.postId) {
          this.showOnePost(notification.postId);
        } else {
          this.spinnerService.hide();
          this.showUsersNetwork(notification.actionUserId);
        }
      }, err => {
        console.log('Error in updating status', err);
      }
    );
  }

  showOnePost(postId) {
    this.postService.getSinglePost(postId).subscribe(
      (res: Post) => {
        this.post = res;
      }, err => {
        this.spinnerService.hide();
        console.log('Error in getting post', err);
      }, () => {
        this.spinnerService.hide();
        localStorage.setItem('currentPost', JSON.stringify(this.post));
        if (window.location.href == "http://stiridea.com/#/post") {
          this.router.navigate(['/aboutUs'])
            .then(() => { this.router.navigate(['/post']) })
        } else {
          this.router.navigate(['/post']);
        }
      }
    );
  }

  dataMassaging(data) {
    data.forEach((post) => {
      let imagePath: string = post.postImagePath;
      if (imagePath && imagePath.lastIndexOf('http://') > 10) {
        post.multipleImages = true;
        post.imagesList = imagePath.split('http://stiridea.com:8080/applications/');
        post.imagesList = post.imagesList.splice(1, post.imagesList.length);
        post.postImagePath = 'http://stiridea.com:8080/applications/' + post.imagesList[1];
      }
      this.allNotifications.push(post);
    });
  }

}
