import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Group } from "../../group";
import { GroupService } from "../../services/group.service";
import { Router } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { PostService } from "../../services/post.service";
import { Post } from "../../post";
import { HostListener } from "@angular/core";
import { Like } from "../../like";
import { CommentsLike } from "../../commentsLike";
import { User } from "../../user";
import { UserService } from "src/app/services/user.service";
import { Parameter } from "src/app/parameter";
import { StoragePostService } from "src/app/services/storage-post.service";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-group-info",
  templateUrl: "./group-info.component.html",
  styleUrls: ["./group-info.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class GroupInfoComponent implements OnInit {
  group: Group;
  cUserId: any;

  showDelete: boolean = true;
  showEdi: boolean = false;
  joined: boolean = false;

  allPosts: Post[] = [];
  somePosts: Post[] = [];

  i: number = 3;
  j: number = 3;
  objectKeys = Object.keys;
  newComment: string;
  currentUser: User;

  bannerImageWidth;
  bannerImageHeight;

  backup: Post[] = [];
  viewedPosts: any[] = [];

  modalPost: Post = new Post();

  params: Parameter = new Parameter();
  limit: number = 10;

  userPrivacy: PrivacyForm = new PrivacyForm();

  @ViewChild("commentsModal") commentsModal;

  selectedColor;
  colors: any[] = [
    "#ff3",
    "#4000ff",
    "#f90606",
    "#ff0080",
    "#00ff40",
    "#8000ff",
    "#00ff80",
    "#808080",
    "#00bfff",
  ];

  constructor(
    private groupService: GroupService,
    private router: Router,
    private userService: UserService,
    private spinnerService: Ng4LoadingSpinnerService,
    private postService: PostService,
    private storageService: StoragePostService
  ) {}

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {
      this.params.offset += this.limit;
      this.getAllPostsOfGroup();
    }
  }

  bottomReached(): boolean {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight;
  }

  ngOnInit() {
    this.group = JSON.parse(localStorage.getItem("selectedGroup"));
    this.cUserId = localStorage.getItem("cUserId");
    this.getUserPrivacyDetails();
    this.getJoined();
    if (this.group.userId == this.cUserId) {
      this.showDelete = false;
    }
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.bannerImageWidth = window.innerWidth * 0.5;
    this.bannerImageHeight = window.innerHeight * 0.4;
    this.getCreatedUserName();
    this.resetParams();
    this.getAllPostsOfGroup();
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllPostsOfGroup() {
    this.spinnerService.show();
    // this.postService.getAllPostsOfGroup(this.group.group_id).subscribe(data => {
    //   this.allPosts = data as any;
    //   this.backup = JSON.parse(JSON.stringify(this.allPosts));
    //   this.viewedPosts = this.allPosts.slice(0, this.i);
    //   this.updateViewPosts();
    //   this.somePosts = this.allPosts.slice(0, this.i);
    // },
    //   error => {
    //     console.log("Error in fetching data", error);
    //   });
    this.postService.getPostsByParams(this.params).subscribe(
      (data: Post[]) => {
        this.dataMassaging(data);
        this.spinnerService.hide();
        this.viewedPosts = data;
        this.updateViewPosts();
      },
      (err) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  dataMassaging(data: Post[]) {
    data.forEach((post) => {
      let imagePath: string = post.imagePath;
      if (imagePath && imagePath.lastIndexOf("http://") > 10) {
        post.multipleImages = true;
        post.imagesList = imagePath.split(
          "http://stiridea.com:8080/applications/"
        );
        post.imagesList = post.imagesList.splice(1, post.imagesList.length);
      }
      post.comments = post.comments.sort((a, b) => b.comment_id - a.comment_id);
      this.somePosts.push(post);
    });
  }

  checkForMoreImages(post) {
    if (post.imagesList && post.imagesList.length > 4) {
      return true;
    }
    return false;
  }

  openPost(post: Post) {
    // localStorage.setItem('pageName', this.pageName);
    localStorage.setItem("currentPost", JSON.stringify(post));
    this.router.navigate(["/post"]);
  }

  dataMassagingOfSinglePost(post: Post) {
    let imagePath: string = post.imagePath;
    if (imagePath && imagePath.lastIndexOf("http://") > 10) {
      post.multipleImages = true;
      post.imagesList = imagePath.split(
        "http://stiridea.com:8080/applications/"
      );
      post.imagesList = post.imagesList.splice(1, post.imagesList.length);
    }
    post.comments = post.comments.sort((a, b) => b.comment_id - a.comment_id);
    return post;
  }

  updateViewPosts() {
    if (this.viewedPosts.length > 0) {
      let viewIds: string = "";
      this.viewedPosts.forEach((post: Post) => {
        viewIds += post.post_id + ",";
      });
      this.postService.updatePostsView(viewIds).subscribe(
        (res) => {},
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getJoined() {
    this.groupService.getJoined(this.cUserId, this.group.group_id).subscribe(
      (res) => {
        this.joined = res as boolean;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteGroup() {
    if (confirm("Are you sure to delete Group ?")) {
      this.groupService.deleteGroup(this.group.group_id).subscribe(
        (res) => {},
        (err) => {
          console.log("Error in deleting group", err);
        },
        () => {
          this.router.navigate(["/groups"]);
        }
      );
    }
  }

  selectedGroup: Group = new Group();

  showEdit() {
    this.showEdi = true;
    this.selectedGroup = JSON.parse(JSON.stringify(this.group));
  }

  updateTitle() {
    this.group = JSON.parse(JSON.stringify(this.selectedGroup));
    this.groupService.update(this.group).subscribe(
      (data) => {
        localStorage.setItem("selectedGroup", JSON.stringify(data));
      },
      (err) => {
        console.log("Error in update", err);
      },
      () => {
        window.location.reload();
      }
    );
  }

  joinGroup() {
    this.groupService.joinGroup(this.group.group_id).subscribe(
      (data) => {
        this.joined = true;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  leaveGroup(group_id: number) {
    this.groupService.leaveGroup(this.group.group_id).subscribe(
      (data) => {
        this.joined = false;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  fileList: FileList;
  image: boolean = false;
  postContent: string;

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    if (this.fileList.length > 1) {
      (document.getElementById("imageTitle") as HTMLInputElement).innerHTML =
        this.fileList.length + " files selected";
    } else {
      (document.getElementById(
        "imageTitle"
      ) as HTMLInputElement).innerHTML = file;
    }
    this.image = true;
  }

  uploadPost() {
    this.spinnerService.show();
    if (this.image) {
      this.uploadWithImage();
    } else {
      this.uploadWithoutImage();
    }
  }

  uploadWithImage() {
    let fileArray: File[] = [];
    for (let i = 0; i < this.fileList.length; i++) {
      fileArray.push(this.fileList[i]);
    }
    this.postService
      .addPostInGroup(fileArray, this.postContent, this.group.group_id, this.selectedColor)
      .then(
        (data) => {
          this.spinnerService.hide();
          this.router.navigate(["/aboutUs"]).then(() => {
            this.router.navigate(["/group-info"]);
          });
        },
        (error) => {
          console.log(error);
          this.spinnerService.hide();
        }
      );
  }

  uploadWithoutImage() {
    this.postService
      .addPostWithoutImageInGroup(this.postContent, this.group.group_id, this.selectedColor)
      .subscribe(
        (data) => {
          this.router.navigate(["/aboutUs"]).then(() => {
            this.router.navigate(["/group-info"]);
          });
          this.spinnerService.hide();
        },
        (error) => {
          console.log(error);
          this.spinnerService.hide();
        }
      );
  }

  checkLike(likes: Like[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doLikePost(post_id: number) {
    this.postService.doLikeOnPost(post_id).subscribe(
      (data) => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      (error) => {
        console.log("error in like", error);
      }
    );
  }

  doUnLikePost(post_id: number) {
    this.postService.doUnLikeOnPost(post_id).subscribe(
      (data) => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      (error) => {
        console.log("error in Unlike", error);
      }
    );
  }

  doLikeComment(comment_id: number, post_id: number) {
    this.postService.doLikeOnComment(comment_id, post_id).subscribe(
      (data) => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
            this.modalPost = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      (error) => {
        console.log("error in like on comment", error);
      }
    );
  }

  doUnLikeComment(comment_id: number, post_id) {
    this.postService.doUnLikeOnComment(comment_id, post_id).subscribe(
      (data) => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
            this.modalPost = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      (error) => {
        console.log("error in Unlike on Comment", error);
      }
    );
  }

  doCommentOnPost(post_id: number) {
    var newComment = (document.getElementById(
      "newComment" + post_id
    ) as HTMLInputElement).value;
    this.postService.doCommentOnPost(post_id, newComment).subscribe(
      (data) => {
        let Apost: Post = data as any;
        for (let post of this.somePosts) {
          if (post.post_id == Apost.post_id) {
            var index = this.somePosts.indexOf(post);
            this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
          }
        }
      },
      (error) => {
        console.log("Error in Comments ", error);
      }
    );
  }

  showUserNetwork(user_id) {
    localStorage.setItem("selectedUser_id", user_id);
    this.router.navigate(["/showUserProfile"]);
  }

  focusReply(id) {
    document.getElementById("newComment" + id).focus();
  }

  shareOnFB(post: Post) {
    let url = "stiridea.com%2F%23sharedPost%2F" + post.post_id;
    window.open("https://www.facebook.com/share.php?u=" + url);
  }

  shareOnTwitter(post: Post) {
    window.open(
      "https://twitter.com/share?original_referer=/&text=" +
        post.content +
        "&url=" +
        post.imagePath
    );
  }

  shareOnGoogle(post: Post) {
    window.open("https://plus.google.com/share?url=" + post.imagePath);
  }

  shareOnLinkedin(post: Post) {
    window.open("https://www.linkedin.com/cws/share?url=" + post.imagePath);
  }

  textAreaEmpty(event, postId) {
    var newComment = (document.getElementById(
      "newComment" + postId
    ) as HTMLInputElement).value;
    var button = document.getElementById(
      "button" + postId
    ) as HTMLButtonElement;
    if (newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  editablePost: Post = new Post();

  editPost(post: Post) {
    this.editablePost = JSON.parse(JSON.stringify(post));
    document.getElementById("editPostButton").click();
  }

  updatePost() {
    this.postService.updatePost(this.editablePost).subscribe(
      (response) => {},
      (err) => {
        console.log("Error in Update", err);
      }
    );
  }

  deletePost(postId, index) {
    if (confirm("Are you sure to delete post ?")) {
      this.postService.deletePost(postId).subscribe(
        (data) => {
          console.log(data);
        },
        (err) => {
          console.log("Error in delete", err);
        },
        () => {
          this.somePosts.splice(index, 1);
        }
      );
    }
  }

  subject: String = "";
  feedback: String = "";
  feedbackPost: Post;

  savePost(post) {
    this.postService.savePost(post).subscribe(
      (res) => {
        if (res) {
          window.alert("Post saved successfully!");
        } else {
          window.alert("Something went wrong.");
        }
      },
      (err) => {
        console.log("Error while saving post", err);
      }
    );
  }

  hidePost(post) {
    let index = this.somePosts.indexOf(post);
    this.somePosts.splice(index, 1);
  }

  feeback(post) {
    this.subject = "";
    this.feedback = "";
    this.feedbackPost = post;
    document.getElementById("feedbackButton").click();
  }

  sendFeedback() {
    let feedback = {
      subject: this.subject,
      feedback: this.feedback,
      post: this.feedbackPost,
      userId: this.cUserId,
    };
    this.userService.sendFeedback(feedback).subscribe((res) => {
      console.log(res);
    });
  }

  dateSelected(event) {
    var date: Date = event.value;
    this.somePosts = [];
    this.resetParams();
    this.params.uploadDate = this.formatDate(date);
    this.getAllPostsOfGroup();
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  resetParams() {
    this.params = new Parameter();
    this.params.groupId = this.group.group_id;
  }

  fileChangeForCover(event) {
    this.fileList = event.target.files;
    this.spinnerService.show();
    this.uploadCoverImage();
  }

  uploadCoverImage() {
    let file: File = this.fileList[0];
    this.groupService.uploadCoverImage(file, this.group.group_id).subscribe(
      (data) => {
        this.group = data as any;
        localStorage.setItem("selectedGroup", JSON.stringify(this.group));
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCreatedUserName() {
    this.userService.getUser(this.group.userId).subscribe(
      (data: User) => {
        this.group.createdUserName = data.userName;
      },
      (err) => {
        console.log("Error in fetching userName", err);
      }
    );
  }

  likesModalHeading: string = "Likes";
  likedByUsers: User[] = [];

  showLikesModal(heading, post: Post) {
    if (heading == "Likes") {
      this.userService.getUsersWhoLikedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        },
        (err) => {
          console.log("Error in fetching users", err);
        }
      );
    } else {
      this.userService.getUsersWhoSharedPost(post.post_id).subscribe(
        (res: User[]) => {
          this.likedByUsers = res;
        },
        (err) => {
          console.log("Error in fetching users", err);
        }
      );
    }
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  downloadPost(post: Post) {
    if (post.imagePath) {
      if (post.multipleImages) {
        window.open(
          "http://stiridea.com:8080/applications/" + post.imagesList[0]
        );
      } else {
        window.open(post.imagePath);
      }
    }
  }

  followUser(user_id) {
    this.userService.followUser(this.cUserId, user_id).subscribe(
      (data) => {
        this.allPosts.forEach((post) => {
          if (post.user_id == user_id) {
            post.follow = 1;
          }
        });
      },
      (error) => {
        console.log("Error in following", error);
      }
    );
  }

  unfollowUser(user_id) {
    if (confirm("Are you sure to unfollow ?")) {
      this.userService.unfollowUser(this.cUserId, user_id).subscribe(
        (data) => {
          this.allPosts.forEach((post) => {
            if (post.user_id == user_id) {
              post.follow = 0;
            }
          });
        },
        (error) => {
          console.log("Error in following", error);
        }
      );
    }
  }

  showTriangle: boolean = false;

  showUsersPosts(userId) {
    this.somePosts = [];
    this.resetParams();
    this.params.user_id = userId;
    this.showTriangle = true;
    this.getAllPostsOfGroup();
  }

  openCommentsModal(post: Post) {
    this.modalPost = post;
  }

  commentLikeEvent(event) {
    let Apost = this.storageService.modalPost;
    for (let post of this.somePosts) {
      if (post.post_id == Apost.post_id) {
        var index = this.somePosts.indexOf(post);
        this.somePosts[index] = this.dataMassagingOfSinglePost(Apost);
        break;
      }
    }
  }

  goBack() {
    this.somePosts = [];
    this.showTriangle = false;
    this.resetParams();
    this.getAllPostsOfGroup();
  }

  setBackgroundColor(color) {
    this.selectedColor = color;
    var iframe = document.getElementsByTagName("iframe")[0];
    iframe.style.backgroundColor = color;
  }
}
