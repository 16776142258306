import { Component, OnInit } from '@angular/core';
import { StoragePostService } from '../../services/storage-post.service';
import { User } from '../../user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  user: User;

  pwd: string;
  newPWD: string;
  reNewPWD: string;

  bothNotMatched: boolean = false;
  pwdNotMatched: boolean = false;
  success: boolean = false;

  constructor(private dataStorage: StoragePostService, private userService: UserService) {
    this.user = this.dataStorage.getUser();
  }

  ngOnInit() {
  }

  updatePassword() {
    if (this.newPWD == this.reNewPWD) {
      this.userService.updatePassword(this.pwd, this.newPWD).subscribe(
        data => {
          if (data) {
            this.success = true;
            this.bothNotMatched = false;
            this.pwdNotMatched = false;
          }else{
            this.success = false;
            this.bothNotMatched = false;
            this.pwdNotMatched = true;
          }
        }, error => {
          console.log('error in updatePassword', error);
        }
      );
    } else {
      this.bothNotMatched = true;
      this.pwdNotMatched = false;
      this.success = false;
    }
  }
}
