import { opinionAnswer } from "./opinionAnswer";
import { OpinionLike } from "./opinionlike";
import { OpinionComment } from "./opinioncomment";

export class Opinion {

    content: string;
    opinionImage: string;
    opinion_id: number;
    opinionlikes: OpinionLike[];
    opinioncomments: OpinionComment[];
    opinionAnswers: opinionAnswer[];
    yes: number;
    no: number;
    confuse: number;
    data: Number[];
    createdDate?: Date;
    isActive?: number = 0;
    isEdit?: boolean = false;
}