import { Component, OnInit } from "@angular/core";
import { User } from "../../user";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { PrivacyForm } from "src/app/settings/models/privacyForm";

@Component({
  selector: "app-search-user",
  templateUrl: "./search-user.component.html",
  styleUrls: ["./search-user.component.css"],
})
export class SearchUserComponent implements OnInit {
  allUsers: User[] = [];
  backedList: User[] = [];
  cUserId;
  keyword: string;

  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(
    private userService: UserService,
    private router: Router,
    private location: Location
  ) {
    if (!localStorage.getItem("user")) {
      location.replaceState("/");
      router.navigate(["/"]);
    }
  }

  ngOnInit() {
    this.cUserId = +localStorage.getItem("cUserId");
    this.getUserPrivacyDetails();
    this.getAllUsers();
  }

  getUserPrivacyDetails() {
    this.userService
      .getUserPrivacyDetails(localStorage.getItem("cUserId"))
      .subscribe(
        (resp: PrivacyForm) => {
          this.userPrivacy = resp;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getAllUsers() {
    this.userService.getAllUsersForSearch().subscribe(
      (data) => {
        this.allUsers = data as any;
        for (let index = 0; index < this.allUsers.length; index++) {
          if (this.allUsers[index].user_id == this.cUserId) {
            this.allUsers.splice(index, 1);
            break;
          }
        }
        this.backedList = this.allUsers;
      },
      (error) => {
        console.log("Error getting Groups", error);
      }
    );
  }

  followUser(userId) {
    this.userService.followUser(this.cUserId, userId).subscribe(
      (data) => {
        this.allUsers.forEach((user) => {
          if (user.user_id == userId) {
            user.follow = 1;
          }
        });
      },
      (error) => {
        console.log("Error in following", error);
      }
    );
  }

  unfollowUser(userId) {
    this.userService.unfollowUser(this.cUserId, userId).subscribe(
      (data) => {
        this.allUsers.forEach((user) => {
          if (user.user_id == userId) {
            user.follow = 0;
          }
        });
      },
      (error) => {
        console.log("Error in following", error);
      }
    );
  }

  showUserNetwork(user_id) {
    localStorage.setItem("selectedUser_id", user_id);
    this.router.navigate(["/showUserProfile"]);
  }

  search() {
    var list: User[] = this.backedList;
    this.allUsers = [];
    if (this.keyword == "") {
      this.allUsers = this.backedList;
    } else {
      for (let index = 0; index < list.length; index++) {
        if (
          list[index].firstName
            .toUpperCase()
            .includes(this.keyword.toUpperCase()) ||
          list[index].lastName
            .toUpperCase()
            .includes(this.keyword.toUpperCase())
        ) {
          this.allUsers.push(list[index]);
        }
      }
    }
  }
}
