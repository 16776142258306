import { Component, OnInit, ViewChild } from '@angular/core';
import { BlogList } from 'src/app/blogList';
import { PostService } from 'src/app/services/post.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { Like } from 'src/app/like';
import { CommentsLike } from 'src/app/commentsLike';
import { User } from 'src/app/user';
import { UserService } from 'src/app/services/user.service';
import { Post } from 'src/app/post';
import { PrivacyForm } from 'src/app/settings/models/privacyForm';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  @ViewChild('commentsModal') commentsModal;

  blogList: BlogList = new BlogList();
  newBlogList: BlogList = new BlogList();
  cUserId: number;
  fileList: FileList;
  newComment: string = '';
  blogListId: any;
  objectKeys = Object.keys;

  showEdit: boolean = false;
  showEditOption: boolean = false;
  showContentEdit: boolean = false;

  bannerImageWidth;
  bannerImageHeight;

  likesModalHeading: string = 'Likes';
  likedByUsers: User[] = [];

  subscribers: User[] = [];
  currentUser: User = new User();

  modalPost: Post = new Post();
  userPrivacy: PrivacyForm = new PrivacyForm();

  constructor(private postService: PostService, private spinner: Ng4LoadingSpinnerService,
    private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.cUserId = Number(localStorage.getItem('cUserId'));
    this.getUserPrivacyDetails();
    this.bannerImageWidth = window.innerWidth * .5;
    this.bannerImageHeight = window.innerHeight * .4;
    if (sessionStorage.getItem('blogList') == undefined) {
      this.router.navigate(['/']);
    }
    this.blogListId = sessionStorage.getItem('blogList');
    this.getBlogList();
    this.getAllBlogUnderBlogList();
    this.getSubscribersList();
  }

  getUserPrivacyDetails() {
    this.userService.getUserPrivacyDetails(this.cUserId).subscribe(
      (resp: PrivacyForm) => {
        this.userPrivacy = resp;
      }, err => {
        console.log(err);
      }
    );
  }

  getBlogList() {
    this.postService.getSingleBlogList(this.blogListId).subscribe(
      (data: BlogList) => {
        data.comments = data.comments.sort((a, b) => b.comment_id - a.comment_id);
        this.blogList = data;
        this.newBlogList = JSON.parse(JSON.stringify(data));
        if (data.userId === this.cUserId) {
          this.showEdit = true;
        }
      }, err => {
        console.log(err);
      }
    );
  }

  getSubscribersList() {
    this.userService.getSubscribersList(sessionStorage.getItem('blogList')).subscribe(
      (data: User[]) => {
        this.subscribers = data;
      }, err => {
        console.log(err);
      }
    );
  }

  showEditToggle() {
    this.showEditOption = true;
  }

  updateBlog() {
    this.spinner.show();
    this.newBlogList.likes = [];
    this.newBlogList.comments = [];
    this.postService.updateBlog(this.newBlogList).subscribe(
      (data: BlogList) => {
        data.comments = data.comments.sort((a, b) => b.comment_id - a.comment_id);
        this.blogList = data;
        this.newBlogList = JSON.parse(JSON.stringify(data));
        this.showEditOption = false;
        this.showContentEdit = false;
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.spinner.hide();
      }
    );
  }

  deleteBlog() {
    this.postService.deleteBlog(this.blogList.blogId).subscribe(
      data => {
        this.router.navigate([sessionStorage.getItem('pPage')]);
      }, err => {
        console.log(err);
      }
    );
  }

  toggleContentEdit() {
    this.showContentEdit = true;
  }

  fileChangeForCover(event) {
    this.fileList = event.target.files;
    this.spinner.show();
    this.uploadCoverImage();
  }

  async uploadCoverImage() {
    let file: File = this.fileList[0];
    await this.postService.uploadImage(file).then(
      (data: any) => {
        let fileName = data.fileName;
        this.newBlogList.imagePath = fileName;
        this.updateBlog();
      },
      error => {
        console.log(error)
      }
    )
  }

  checkLike(likes: Like[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  checkLikeOnComment(likes: CommentsLike[]): boolean {
    for (let i = 0; i < likes.length; i++) {
      if (likes[i].user_id == this.cUserId) {
        return true;
      }
    }
    return false;
  }

  doLikePost(blogId: number) {
    this.postService.doLikeOnBlogs(blogId).subscribe(
      data => {
        this.updatePostInfo(data);
      },
      error => {
        console.log("error in like", error);
      }
    )
  }

  doUnLikePost(blogId: number) {
    this.postService.doUnLikeOnBlogs(blogId).subscribe(
      data => {
        this.updatePostInfo(data);
      },
      error => {
        console.log("error in Unlike", error);
      }
    )
  }

  doLikeComment(comment_id: number, blogId: number) {
    this.postService.doLikeOnCommentOnBlogs(comment_id, blogId).subscribe(
      data => {
        this.updatePostInfo(data);
      },
      error => {
        console.log("error in like on comment", error);
      }
    )
  }

  doUnLikeComment(comment_id: number, blogId) {
    this.postService.doUnLikeOnComment(comment_id, blogId).subscribe(
      data => {
        this.updatePostInfo(data);
      },
      error => {
        console.log("error in Unlike on Comment", error);
      }
    )
  }

  doCommentOnPost(blogId: number) {
    this.postService.doCommentOnPostOnBlogs(blogId, this.newComment).subscribe(
      data => {
        this.newComment = '';
        this.updatePostInfo(data);
      },
      error => {
        console.log("Error in Comments ", error);
      }
    )
  }

  showUserNetwork(user_id) {
    localStorage.setItem('selectedUser_id', user_id);
    this.router.navigate(['/showUserProfile']);
  }

  focusReply(id) {
    document.getElementById('newComment' + id).focus();
  }

  textAreaEmpty(event, postId) {
    var newComment = ((document.getElementById("newComment" + postId) as HTMLInputElement).value);
    var button = document.getElementById("button" + postId) as HTMLButtonElement;
    if (newComment.length > 0) {
      button.hidden = false;
    } else {
      button.hidden = true;
    }
  }

  showLikesModal(heading, post: Post) {
    this.userService.getUsersWhoLikedBlog(post.post_id).subscribe(
      (res: User[]) => {
        this.likedByUsers = res;
      }, err => {
        console.log('Error in fetching users', err);
      }
    );
    this.likesModalHeading = heading;
    document.getElementById("likesModalButton").click();
  }

  commentLikeEvent(event: string) {
    if (event.indexOf('u') > -1) {
      this.doUnLikeComment(Number(event.substring(2)), this.blogList.blogId);
    } else {
      this.doLikeComment(Number(event.substring(2)), this.blogList.blogId);
    }
  }

  goBack() {
    this.router.navigate(['/' + sessionStorage.getItem('pPage')]);
  }

  image: boolean = false;
  postContent: string;

  fileChange(event) {
    this.fileList = event.target.files;
    let file = event.target.files[0].name;
    ((document.getElementById("imageTitle") as HTMLInputElement)).innerHTML = file;
    this.image = true;
  }

  uploadPost() {
    this.spinner.show();
    if (this.image) {
      this.uploadWithImage();
    } else {
      this.uploadWithoutImage();
    }
  }

  uploadWithImage() {
    let file: File = this.fileList[0];
    this.postService.addPostInBlogs(file, this.postContent, this.blogList.blogId).subscribe(
      data => {
        this.spinner.hide();
        this.router.navigate(['/aboutUs'])
          .then(() => { this.router.navigate(['/myBlogs']) });
      },
      error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  uploadWithoutImage() {
    this.postService.addPostWithoutImageInBlogs(this.postContent, this.blogList.blogId).subscribe(
      data => {
        this.spinner.hide();
        this.router.navigate(['/aboutUs'])
          .then(() => { this.router.navigate(['/myBlogs']) })
      },
      error => {
        console.log(error);
        this.spinner.hide();
      });
  }

  somePosts: Post[] = [];

  getAllBlogUnderBlogList() {
    this.postService.getAllBlogsOfUser(this.blogListId, 'all').subscribe(
      (data: Post[]) => {
        this.somePosts = data;
        this.backup = JSON.parse(JSON.stringify(data));
      }, err => {
        console.log(err);
      }
    );
  }

  private updatePostInfo(data) {
    let Apost: Post = data as any;
    for (let post of this.somePosts) {
      if (post.post_id == Apost.post_id) {
        var index = this.somePosts.indexOf(post);
        this.somePosts[index] = Apost;
      }
    }
  }

  editablePost: Post = new Post();
  newContent: string = '';

  editPost(post: Post) {
    this.editablePost = post;
    this.newContent = post.content;
    document.getElementById('editPostButton').click();
  }

  updatePost() {
    this.editablePost.content = this.newContent;
    this.postService.updateBlogs(this.editablePost).subscribe(
      response => {
      }, err => {
        console.log('Error in Update', err);
      }
    );
  }

  deletePost(postId, index) {
    if (confirm("Are you sure to delete post ?")) {
      this.postService.deleteBlogs(postId).subscribe(
        data => {
          console.log(data);
        }, err => {
          console.log('Error in delete', err);
        }, () => {
          this.somePosts.splice(index, 1);
        }
      );
    }
  }

  openCommentsModal(post: Post) {
    this.modalPost = post;
  }

  backup: Post[] = [];

  dateSelected(event) {
    var date: Date = event.value;
    this.somePosts = [];
    for (let index = 0; index < this.backup.length; index++) {
      var postDate = new Date(this.backup[index].uploadDate);
      if (postDate.getDate() == date.getDate() && postDate.getFullYear() == date.getFullYear() && postDate.getMonth() == date.getMonth()) {
        this.somePosts.push(this.backup[index]);
      }
    }
  }

  hidePost(post) {
    let index = this.somePosts.indexOf(post);
    this.somePosts.splice(index, 1);
  }

  savePost(post) {
    this.postService.savePost(post).subscribe(
      res => {
        if (res) {
          window.alert('Post saved successfully!');
        } else {
          window.alert('Something went wrong.');
        }
      }, err => {
        console.log('Error while saving post', err);
      }
    );
  }

  subject: String = '';
  feedback: String = '';
  feedbackPost: Post;

  feeback(post) {
    this.subject = '';
    this.feedback = '';
    this.feedbackPost = post;
    document.getElementById('feedbackButton').click();
  }

  sendFeedback() {
    let feedback = {
      'subject': this.subject,
      'feedback': this.feedback,
      'post': this.feedbackPost,
      'userId': this.cUserId
    }
    this.userService.sendFeedback(feedback).subscribe(
      res => {
        console.log(res);
      }
    );
  }

}
